/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routesList } from "../Common";
import { getcurrentlyConnectWallet } from "../Scripts/interact";

const useWalletChange = () => {
  const [wallet, setWallet] = useState({
    address: null,
    balance: null,
    chainId: null,
  });
  const [walletConnected, setWalletConnected] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(async () => {
    await fetchData();
  }, [wallet?.address]);

  const fetchData = async () => {
    const { status, message, address, walletBalance, chainId } =
      await getcurrentlyConnectWallet();

    if (status) {
      setWalletConnected(true);
    } else {
      setWalletConnected(false);
      if (window.location.pathname === routesList.mint) {
        navigate(routesList.indexPage);
      }
    }
    setWallet(() => {
      return { address: address, balance: walletBalance, chainId: chainId };
    });
    setMessage(() => {
      return message;
    });
    monitorWalletChange();
  };

  const monitorWalletChange = async () => {
    const { ethereum } = window;
    if (ethereum) {
      try {
        const connectAddresses = await ethereum.on(
          "accountsChanged",
          (accounts) => {
            if (accounts.length > 0) {
              setMessage(`Current Wallet is ${accounts[0]}`);
              setWallet(() => {
                return { address: accounts[0], balance: null, chainId: null };
              });
            } else {
              setMessage(
                "Connect to MetaMask by Clicking 'Connect to Wallet' Button"
              );
              setWallet({ address: null, balance: null, chainId: null });
            }
          }
        );
        // console.log("Selected Address: ", connectAddresses?.selectedAddress);
      } catch (error) {
        return {
          status: false,
          address: "",
          message: error.message,
        };
      }
    } else {
      return {
        status: false,
        address: "",
        message: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://metamask.io/download.html`}
              >
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };
  return {
    isWalletConnected: walletConnected,
    changedAddress: wallet?.address,
    changedBalance: wallet?.balance,
    changedChainId: wallet?.chainId,
    changedMessage: message,
  };
};
export default useWalletChange;