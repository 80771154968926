import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routesList } from "../Common";
import { CoreLayout } from "../Layout";
import HomePage from "../Components/HomePage";
import Mint from "../Components/Mint";
import Interactions from "../Components/Interactions";
import About from "../Components/About";
import Team from "../Components/Team";
import Provenance from "../Components/Provenance";
import Members from "../Components/Members";
import TnC from "../Components/TnC";
import Collections from "../Components/Collections";
import RoadMap from "../Components/RoadMap";
import IndexPage from "../Components/IndexPage";
import Footer from "../Components/Footer";
import Infographic from "../Components/Infographic";

export default function Routing() {
  return (
    <Router>
      <Routes>
        <Route
          exact
          path={routesList.indexPage}
          element={
            <>
              <IndexPage />
              <Footer />
            </>
          }
        ></Route>
        <Route exact path={routesList.indexPage} element={<CoreLayout />}>
          <Route exact path={routesList.homePage} element={<HomePage />} />
          <Route
            exact
            path={routesList.interactions}
            element={<Interactions />}
          />
          <Route exact path={routesList.about} element={<About />} />
          <Route exact path={routesList.team} element={<Team />} />
          <Route exact path={routesList.provenance} element={<Provenance />} />
          <Route exact path={routesList.members} element={<Members />} />
          <Route
            exact
            path={routesList.collections}
            element={<Collections />}
          />
          <Route exact path={routesList.roadmap} element={<RoadMap />} />
          <Route exact path={routesList.tnc} element={<TnC />} />
          
        </Route>
        <Route exact path={routesList.mint} element={<><Mint /><Footer/></>} />
        <Route exact path={routesList.infographic} element={<Infographic />} />
      </Routes>
    </Router>
  );
}
