import { CommonSubmitButtonService, MintButtonService } from "../Services";

export const loaderType = {
  BUTTON: "button",
  MINT: "mint",
  INLINE: "inline",
  LIST: "list",
};

export const loaderClass = {
  BUTTON: "",
  MINT: "mintButton",
  INLINE: "loaderInline",
  LIST: "loaderList",
};

export const StartLoader = (type) => {
  switch (type) {
    case loaderType.BUTTON:
      CommonSubmitButtonService.startLoading();
      break;

    case loaderType.MINT:
      MintButtonService.startLoading();
      break;

    // case loaderType.INLINE:
    //   CommonInlineLoaderService.startLoading(loaderClass.INLINE);
    //   break;

    // case loaderType.LIST:
    //   CommonListLoaderService.startLoading(loaderClass.LIST);
    //   break;
    default:
  }
};

export const StopLoader = (type) => {
  switch (type) {
    case loaderType.BUTTON:
      CommonSubmitButtonService.stopLoading();
      break;

    case loaderType.MINT:
      MintButtonService.stopLoading();
      break;
    // case loaderType.INLINE:
    //   CommonInlineLoaderService.stopLoading();
    //   break;

    // case loaderType.LIST:
    //   CommonListLoaderService.stopLoading();
    //   break;
    default:
  }
};
