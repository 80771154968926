import { Store } from "react-notifications-component";
import { notificationType, notificationPosition } from "./CommonConstants";
import API from "./AxiosConfiguration";

export const showNotification = (type, message, ...restParamsArray) => {
  const rest = restParamsArray[0];
  Store.addNotification({
    title: rest?.title,
    message: message,
    type: type === notificationType.ERROR ? notificationType.DANGER : type,
    insert: "top",
    container: rest.notificationPosition || notificationPosition.TOP_RIGHT,
    animationIn: ["animate__animated animate__fadeIn"],
    animationOut: ["animate__animated animate__fadeOut"],
    dismiss: {
      showIcon: rest.showCloseIcon || true,
      duration: rest.duration || 4000,
    },
  });
};

export const sendPostRequest = async (url, data, ...options) => {
  try {
    const response = await API.post(url, data, options);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const manageCounterProcessing = (buttonType, min, value, max) => {
  let newValue;
  // debugger;
  if (buttonType === "minus") {
    if (parseInt(value) <= parseInt(min)) {
      return parseInt(value);
    } else {
      newValue = parseInt(value) - 1;
    }
  } else if (buttonType === "plus") {
    if (parseInt(value) >= parseInt(max)) {
      return parseInt(value);
    } else {
      newValue = parseInt(value) + 1;
    }
  }
  return newValue;
};

export function checkEven(value) {
  // debugger;
  if (value && parseInt(value) > 0) {
    if (parseInt(value) % 2 === 0) {
      return true;
    }
  }
  return false;
}

export function checkOdd(value) {
  // debugger;
  if (value && parseInt(value) > 0) {
    if (parseInt(value) % 2 !== 0) {
      return true;
    }
  }
  return false;
}

export const checkEvenOddInArray = (array) => {
  let tempObj = {
    evenCount: 0,
    oddCount: 0,
    evenIndex: [],
    oddIndex: [],
  };
  if (array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      if (checkEven(array[i])) {
        tempObj.evenCount++;
        tempObj.evenIndex.push(i);
      } else if (checkOdd(array[i])) {
        tempObj.oddCount++;
        tempObj.oddIndex.push(i);
      }
    }
  }
  return tempObj;
};

export function manageUndefined(value) {
  if (value === "undefined" || value === undefined || value === null) {
    return "";
  }
  return value;
}

export function convertArrayFromArrayishString(arrayishString) {
  var trimmed = arrayishString.replace(/^[+|]+$/gi, ""); // Trim [ and ] from start and end from string

  console.log("Trimmed:", trimmed.split(","));
}

export function checkExist(value) {
  if (value !== undefined && value !== "" && value !== null) {
    return true;
  }
  return false;
}

export function checkBlank(value) {
  if (value !== undefined && value === "") {
    return true;
  }
  return false;
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}

export const digitFormatter = (value) => {
  if (value !== undefined && value !== "") {
    let tempValue = value;
    if (value > 999 && value < 1000000) {
      let formatted = (value / 1000).toFixed(1);
      tempValue = `${value % 1000 === 0 ? parseInt(formatted) : formatted} K`;
    } else if (value >= 1000000) {
      let formatted = (value / 1000000).toFixed(1);
      tempValue = `${value % 1000000 === 0 ? parseInt(formatted) : formatted} M`;
    } else if (value <= 999) {
      tempValue = `${parseFloat(value).toFixed(2)}`;
    }
    return tempValue;
  }
  return "";
};
