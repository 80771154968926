import React from "react";

const RoadMap = () => {
  return (
    <>
      <section className="innner-banner roadmap">
        <div className="container">
          <div className="ban-canvas">
            <div className="big-title">Roadmap</div>
          </div>
        </div>
      </section>

      <section className="roadmap-list section-pd">
        <div className="container">
          <h2>Roadmap</h2>
          <div className="row">
            <div className="col-lg-8">
              <div className="roadmap-item">
                <div className="title1">Women Unite Launch Day</div>
                <p>
                  <span className="completed">
                    <img src="images/tick.png" alt="" />
                  </span>
                  <em>
                    <b>20th January 2022,</b> 222 Original Collection tokens
                    inner circle community release (SOLD OUT!) Silver & Gold
                    Throne Passes
                  </em>
                </p>
              </div>
              <div className="roadmap-item">
                <div className="title1">Silver & Gold Throne Passes</div>
                <p>
                  <span className="completed">
                    <img src="images/tick.png" alt="" />
                  </span>
                  <em>
                    <b>22nd January 2022</b> Release of 2000 Silver Throne Pass
                    and 222 Gold Throne Pass
                  </em>
                </p>
              </div>
              <div className="roadmap-item">
                <div className="title1">Private Launch Pad</div>
                <p>
                  <span className="completed">
                    <img src="images/tick.png" alt="" />
                  </span>
                  <em>
                    Cross collaboration with Super Creators by IAC (Offering
                    whitelist MINT spaces to all OC and GOLD Pass owners for the
                    Super Creators Launch on 2/2/22)
                  </em>
                </p>
              </div>
              <div className="roadmap-item">
                <div className="title1">Influencer Outreach</div>
                <p>
                  <span className="completed">
                    <img src="images/tick.png" alt="" />
                  </span>
                  <em>
                    Initiate Influencer Campaigns, PRESS RELEASE, and
                    Collaborations
                  </em>
                </p>
              </div>
              <div className="roadmap-item">
                <div className="title1">Launch Tridents</div>
                <p>
                  <span className="completed">
                    <img src="images/tick.png" alt="" />
                  </span>
                  <em>April 2022 Launch 222 Red Trident</em>
                </p>
                <p>
                  <span className="completed">
                    <img src="images/tick.png" alt="" />
                  </span>
                  <em>June 2022 Launch 222 Blue Trident</em>
                </p>
                <p>
                  <span className="uncheck">
                    <img src="images/tick.png" alt="" />
                  </span>
                  <em>Q1 2023 Launch 222 Gold Trident</em>
                </p>
              </div>
              <div className="roadmap-item">
                <div className="title1">
                  Launch Women Unite - 10K Assemble Collection
                </div>
                <p>
                  <span className="completed">
                    <img src="images/tick.png" alt="" />
                  </span>
                  <em>
                    <b>8th April 2022</b> Launch of 2nd collection of 10,000
                    tokens, Women Unite - 10K Assemble Collection (FREE MINT for
                    ALL THRONE PASS HOLDERS)
                  </em>
                </p>
              </div>
              <div className="roadmap-item">
                <div className="title1">
                  Charity Contribution
                </div>
                <p>
                  <span className="completed">
                    <img src="images/tick.png" alt="" />
                  </span>
                  <em>
                  Contribute 10% of profits from the Women Unite projects to support Women in need
                  </em>
                </p>
              </div>
              <div className="roadmap-item">
                <div className="title1">Women Unite Virtual Gala Event</div>
                <p>
                  <span className="uncheck">
                    <img src="images/tick.png" alt="" />
                  </span>
                  <em>Host the Grand Virtual Gala Event for all Women Unite Token Holders & special guests (Date TBD)</em>
                </p>
              </div>
              <div className="roadmap-item">
                <div className="title1">Beta Launch of the WU Kingdom</div>
                <p>
                  <span className="uncheck">
                    <img src="images/tick.png" alt="" />
                  </span>
                  <em>
                    Launch An exclusive hub for private masterminds, influencer
                    networking, and select coaching sessions (For Throne Pass
                    Holders Only!)
                  </em>
                </p>
              </div>
              <div className="roadmap-item">
                <div className="title1">
                  Launch The Forgotten Warriors Collection
                </div>
                <p>
                  <span className="uncheck">
                    <img src="images/tick.png" alt="" />
                  </span>
                  <em>
                    The 3rd collection by Women Unite 11,111 tokens (FREE MINT
                    for all Original Collection, Silver Throne Pass, Gold Throne
                    Pass and Trident Owners) (Date TBD)
                  </em>
                </p>
              </div>
              <div className="roadmap-item">
                <div className="title1">Entering The Metaverse</div>
                <p>
                  <span className="uncheck">
                    <img src="images/tick.png" alt="" />
                  </span>
                  <em>
                    Plans for the WU Kingdom to enter into the metaverse,
                    creating a space to empower women virtually (Unlimited 24
                    hour Access granted for ALL TOKEN HOLDERS)
                  </em>
                </p>
              </div>
              <div className="roadmap-item">
                <div className="title1">
                  Bringing the WU Palace Into Reality
                </div>
                <p>
                  <span className="uncheck">
                    <img src="images/tick.png" alt="" />
                  </span>
                  <em>
                    A beautiful and elegant palace to be built in The Creator's
                    Land as a physical representation of courage , beauty, and
                    kindness for all women, coming in 2025 (more to be revealed
                    soon!)
                  </em>
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <img
                src="images/bow.png"
                className="bow-img"
                alt=""
                width="350"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="confidence section-pd">
        <div className="container">
          <div className="center-text-canvas">
            <h2>The most beautiful thing a woman can wear is</h2>
            <div className="big-title">Confidence</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RoadMap;
