/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import {
  metamaskErrorMessages,
  notificationSetting,
  notificationType,
  showNotification,
} from "../Common";
import {
  getBaseExtension,
  getBaseURI,
  getContractName,
  getMaxGenCount,
  getnotRevealedUri,
  getOwner,
  getPausedStatus,
  getPrice,
  getPrivateSaleStatus,
  getPublicSaleStatus,
  getRevealedStatus,
  getSymbol,
  getThronePassSale,
  getTotalSupply,
  maxPerMintTokens,
  maxSupply,
  renounceOwnershipStatus,
} from "../Scripts/interact";
import { ReadFunctions } from "./ReadFunctions";
import { WriteFunctions } from "./WriteFunctions";
import useWalletChange from "../hooks/useWalletChange";

const Interactions = () => {
  const [price, setPrice] = useState();
  const [baseExtension, setBaseExtension] = useState("");
  const [baseURI, setBaseURI] = useState("");
  const [maxGenCount, setMaxGenCount] = useState("");
  const [maxPerMint, setMaxPerMint] = useState("");
  const [maxMints, setMaxMints] = useState("");
  const [contractName, setContractName] = useState("");
  const [notRevealedURI, setNotRevealedURI] = useState("");
  const [ownerAddress, setOwnerAddress] = useState("");
  const [paused, setPaused] = useState();
  const [privateSale, setPrivateSale] = useState();
  const [publicSale, setPublicSale] = useState();
  const [renounceOwnership, setRenounceOwnership] = useState("");
  const [thronePassSale, setThronePassSale] = useState("");
  const [revealed, setRevealed] = useState();
  const [symbol, setSymbol] = useState("");
  const [totalSupply, setTotalSupply] = useState("");

  useEffect(async () => {
    getPriceFromContract();
    getBaseURIFromContract();
    getMaxPerMintTokensFromContract();
    // getMaxSupplyFromContract();
    getContractNameFromContract();
    getNotRevealedUriFromContract();
    getOwnerFromContract();
    getPausedFromContract();
    getPrivateSaleFromContract();
    getPublicSaleFromContract();
    getRevealedFromContract();
    getSymbolFromContract();
    getTotalSupplyFromContract();
    getBaseExtensionFromContract();
    getMaxGenCountFromContract();
    // getRenounceOwnershipFromContract();
    getThronePassSaleFromContract();
  }, []);

  //Getter Functions

  const getPriceFromContract = async () => {
    const response = await getPrice();
    if (response?.status) {
      setPrice(() => {
        return response?.price;
      });
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Price ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getBaseExtensionFromContract = async () => {
    const response = await getBaseExtension();
    if (response?.status) {
      setBaseExtension(() => {
        return response?.baseExtension;
      });
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}BaseExtension ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getBaseURIFromContract = async () => {
    const response = await getBaseURI();
    if (response?.status) {
      setBaseURI(() => {
        return response?.baseURI;
      });
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}BaseURI ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getMaxPerMintTokensFromContract = async () => {
    const response = await maxPerMintTokens();
    //console.log("response", response);
    if (response?.status) {
      setMaxPerMint(response?.maxPerMint);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Max per mint ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getMaxGenCountFromContract = async () => {
    const response = await getMaxGenCount();
    // console.log("response Max Supply", response);
    if (response?.status) {
      setMaxGenCount(response?.maxGenCount);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Max Gen Count ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getMaxSupplyFromContract = async () => {
    const response = await maxSupply();
    // console.log("response Max Supply", response);
    if (response?.status) {
      setMaxMints(response?.maxMints);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Max Supply ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getContractNameFromContract = async () => {
    const response = await getContractName();
    // console.log("response Name", response);
    if (response?.status) {
      setContractName(response?.contractName);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Contract name ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getNotRevealedUriFromContract = async () => {
    const response = await getnotRevealedUri();
    // console.log("response Name", response);
    if (response?.status) {
      setNotRevealedURI(response?.notRevealedURI);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Revealed URI ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getOwnerFromContract = async () => {
    const response = await getOwner();
    // console.log("response Name", response);
    if (response?.status) {
      setOwnerAddress(response?.ownerAddress);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Owner ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getPausedFromContract = async () => {
    const response = await getPausedStatus();
    //console.log("response", response);
    if (response?.status) {
      setPaused(response?.pausedStatus);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Paused Status ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getPrivateSaleFromContract = async () => {
    const response = await getPrivateSaleStatus();
    // console.log("response", response);
    if (response?.status) {
      setPrivateSale(response?.privateSaleStatus);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Private Sale Status ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getPublicSaleFromContract = async () => {
    const response = await getPublicSaleStatus();
    //console.log("response", response);
    if (response?.status) {
      setPublicSale(response?.publicSaleStatus);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Public Sale Status ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getRenounceOwnershipFromContract = async () => {
    const response = await renounceOwnershipStatus();
    //console.log("response", response);
    if (response?.status) {
      setRenounceOwnership(response?.renounceOwnership);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Renounce Ownership ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getThronePassSaleFromContract = async () => {
    const response = await getThronePassSale();
    //console.log("response", response);
    if (response?.status) {
      setThronePassSale(response?.thronePassSale);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Throne Pass ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getRevealedFromContract = async () => {
    const response = await getRevealedStatus();
    //console.log("response", response);
    if (response?.status) {
      setRevealed(response?.revealedStatus);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Revealed ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getSymbolFromContract = async () => {
    const response = await getSymbol();
    //console.log("response", response);
    if (response?.status) {
      setSymbol(response?.symbol);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Symbol ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getTotalSupplyFromContract = async () => {
    const response = await getTotalSupply();
    //console.log("response", response);
    if (response?.status) {
      setTotalSupply(response?.totalSupply);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Total Supply ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  return (
    <>
      <section className="section section-pd banner">
        <div className="container">
          <div className="interactiontabs">
            <Tabs
              defaultActiveKey="read"
              variant="pill"
              className="nav-pills-secondary mb-3"
            >
              <Tab eventKey="read" title="Read Contract">
                <ReadFunctions
                  {...{
                    price,
                    baseExtension,
                    baseURI,
                    maxGenCount,
                    maxPerMint,
                    maxMints,
                    contractName,
                    notRevealedURI,
                    ownerAddress,
                    paused,
                    privateSale,
                    publicSale,
                    renounceOwnership,
                    revealed,
                    symbol,
                    totalSupply,
                    thronePassSale,
                  }}
                />
              </Tab>
              <Tab eventKey="write" title="Write Contract">
                <WriteFunctions
                  {...{
                    price,
                    maxGenCount,
                    paused,
                    privateSale,
                    publicSale,
                    revealed,
                    thronePassSale,
                    ownerAddress,
                  }}
                  maxLimitMint={maxPerMint}
                  onUpdatePrivateSaleStatus={() => {
                    getPrivateSaleFromContract();
                    getPausedFromContract();
                  }}
                  onUpdatePublicSaleStatus={() => {
                    getPublicSaleFromContract();
                    getPausedFromContract();
                  }}
                  onUpdateRevealStatus={() => getRevealedFromContract()}
                  onUpdateThronePassSaleStatus={() => {
                    getThronePassSaleFromContract();
                    getPausedFromContract();
                  }}
                  onUpdateBaseURI={() => getBaseURIFromContract()}
                  onUpdateNotRevealedURI={() => getNotRevealedUriFromContract()}
                  onUpdatePriceValue={() => getPriceFromContract()}
                  onUpdateSupplyLimit={() => getMaxGenCountFromContract()}
                  onUpdateMaxMintLimit={() => getMaxPerMintTokensFromContract()}
                  onTransferOwnerShip={() => getOwnerFromContract()}
                  onPause={() => getPausedFromContract()}
                  onUnPause={() => getPausedFromContract()}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
};

export default Interactions;
