export const routesList = {
  indexPage: "/",
  homePage: "/home",
  mint: "/mint",
  interactions: "/11e6e579b5b56d8e8192d8a065db0c11", //women-unite-project-interactions MD5 Hash
  roadmap: "/roadmap",
  about: "/about",
  team: "/team",
  provenance: "/provenance",
  members: "/members",
  collections: "/collections",
  tnc: "/terms-of-service",
  infographic: "/infographic",
};

export const urls = {
  indexPage: routesList.indexPage,
  homePage: routesList.homePage,
  mint: routesList.mint,
  interactions: routesList.interactions,
  roadmap: routesList.roadmap,
  about: routesList.about,
  team: routesList.team,
  provenance: routesList.provenance,
  members: routesList.members,
  collections: routesList.collections,
  tnc: routesList.tnc,
  infographic:routesList.infographic,
};

export const labels = {
  mint: "Mint NFT",
  connect_wallet: "Connect Wallet",
  token_registration: "Token Registration",
  walletOfOwner: "Wallet Of Owner",
  interactions: "/interactions",
  roadmap: "Roadmap",
  about: "About",
  team: "Team",
  provenance: "Provenance",
  members: "Members",
  collections: "Collections",
  tnc: "Terms of Service",
  infographic: "Infographic",
};

export const apiUrls = {
  addMint: `add-mint`,
  addSubscribe: `add-subscribe`,
  checkUser: `check-user`,
  getTeam: `get-team`,
  getThroneHolder: `get-throne-holder`,
  getOcTokenHolder: `get-oc-token-holder`,
  getTokenType: `get-token-type`,
  getCurrentPhase: `get-current-phase`,
};

export const urlQuery = {
  symbol: "?",
  page: "page=",
};
// export const hashSections = {
//   aboutUs: "/#aboutus",
//   roadmap: "/#roadmap",
//   team: "/#team",
// };
