import React, { useEffect, useState } from "react";
import { CommonSubmitButtonService } from "../Services";

export const GlobalSubmit = React.forwardRef((props, ref) => {
  const [active, setActive] = useState();
  const [disabled, setDisabled] = useState(null);
  useEffect(() => {
    setActive(true);
    setDisabled(false);
    return () => {
      setActive(true);
      setDisabled(false);
    };
  }, []);

  CommonSubmitButtonService.loadingState().subscribe((message) => {
    setActive(message);
  });

  return active && props && props !== "" ? (
    <>
      <button
        type={"submit"}
        className={props.className || "btn btn-dark mt-3"}
        {...props}
      >
        {props.label}
        {props.children}
      </button>
    </>
  ) : (
    <>
      <button disabled={disabled} className={"btn btn-dark mt-3"}>
        <span
          className="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"
        ></span>
        {"Processing! Please Wait..."}
        {/* &nbsp;{props.label}
        {props.children} */}
      </button>
    </>
  );
});
GlobalSubmit.displayName = "GlobleSubmit";
