import React from "react";
import { labels } from "../Common";
import { GlobalParagraph as Paragraph } from "../Global";

const TnC = () => {
  return (
    <>
      <section className="content">
        <div className="container tnc">
          <h2 className="title2 mb-4">{labels.tnc}</h2>
          <Paragraph className={"mb-5"}>
            Vulputate mi sit amet mauris. Magna sit amet purus gravida quis
            blandit. Diam ut venenatis tellus in metus.
          </Paragraph>
          <Paragraph title={"1. INTRODUCTION"}>
            At auctor urna nunc id cursus metus aliquam eleifend. Vitae
            ultricies leo integer malesuada. Maecenas sed enim ut sem viverra
            aliquet eget. Gravida in fermentum et sollicitudin. Scelerisque
            purus semper eget duis at tellus at urna condimentum. Sed blandit
            libero volutpat sed. Mi ipsum faucibus vitae aliquet nec ullamcorper
            sit amet. Integer vitae justo eget magna fermentum iaculis eu non.
            Eros in cursus turpis massa tincidunt dui. Arcu cursus euismod quis
            viverra nibh cras pulvinar mattis.
            <small className="text-secondary">
              Enim diam vulputate ut pharetra sit. Auctor neque vitae tempus
              quam pellentesque nec nam aliquam sem.
            </small>
          </Paragraph>
          <Paragraph
            description={
              "Scelerisque viverra mauris in aliquam sem fringilla ut morbi tincidunt. Nisi scelerisque eu ultrices vitae. Commodo ullamcorper a lacus vestibulum sed. A diam maecenas sed enim ut sem viverra. Risus nec feugiat in fermentum posuere urna. Cursus sit amet dictum sit amet justo donec enim diam. Rutrum quisque non tellus orci ac auctor. Placerat orci nulla pellentesque dignissim enim sit amet. Id nibh tortor id aliquet lectus proin nibh. Dictum non consectetur a erat nam at lectus. Etiam erat velit scelerisque in dictum non consectetur."
            }
          />
        </div>
      </section>
    </>
  );
};

export default TnC;
