import { Subject } from "rxjs";
import { apiUrls } from "../Common/URLs";
import API from "../Common/AxiosConfiguration";
const subject = new Subject();

export const CommonService = {
  getObserved: () => subject.asObservable(),
  addMint: async (form) => {
    const response = await API.post(apiUrls.addMint, form);
    return response;
  },

  addSubscribe: async (form) => {
    const response = await API.post(apiUrls.addSubscribe, form);
    return response;
  },

  checkUser: async (form) => {
    const response = await API.post(apiUrls.checkUser, form);
    return response;
  },

  getTeam: async (form) => {
    const response = await API.post(apiUrls.getTeam, form);
    return response;
  },

  getThroneHolder: async (form) => {
    const response = await API.post(apiUrls.getThroneHolder, form);
    return response;
  },
  getOcTokenHolder: async (form) => {
    const response = await API.post(apiUrls.getOcTokenHolder, form);
    return response;
  },
  getTokenType: async (form) => {
    const response = await API.post(apiUrls.getTokenType, form);
    return response;
  },
  getCurrentPhase: async (form) => {
    const response = await API.post(apiUrls.getCurrentPhase, form);
    return response;
  },
};
