/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { routesList, scrollToTop } from "../Common";
import useWalletChange from "../hooks/useWalletChange";

const Collections = () => {
  const { isWalletConnected, changedAddress } = useWalletChange();
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      {/*Banner */}
      <section className="innner-banner collec login">
        <div className="container">
          <div className="ban-canvas">
            <div className="big-title collection-title">
              <span>C</span>ollections
            </div>
          </div>
        </div>
      </section>

      <section className="proven section-pd">
        <div className="container">
          {/*<h2 className="mb-4">Original Collection</h2> */}
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="sub-title">The Original Collection - 222</div>
              <p>
                A distinctive and vibrant collection representing the rise of
                the divine feminine. Power, Love, and Beauty portrayed through
                the creativity of fashion, photography, and NFT.
              </p>
              <p>
                United by a first-of-its-kind collection of 222 special,
                independent, and empowered women which are devoted to uplifting
                the world through positivity and contribution. 10% of all
                royalties are donated towards supporting women in need across
                the globe.
              </p>
              <p>
                “You can be powerful, intelligent, beautiful, and loving at the
                same time. Let us unite and make this world a better place!” -
                Miss Kaina
              </p>
            </div>
            <div className="col-md-12 col-lg-6 text-center">
              <div className="video-frame-about mt-0">
                <img
                  src="images/the-oc.jpg"
                  alt=""
                  title=""
                  className="img-fluid
                imgframeset"
                />
                <div className="iframe-video-bg">
                  <img
                    src="images/video-frame-about.png"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <a
                href="https://opensea.io/collection/womenunite"
                target={"_blank"}
                rel="noreferrer"
                className="btn about-btn mt-5"
              >
                VIEW 222 COLLECTION
              </a>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="sub-title">WU 10K Assemble Collection</div>
              <p>
                The Women Unite - 10K Assemble is a collection of 10,000
                hand-taken photos of real women all across the globe transformed
                into NFTs. Each model is carefully fitted with a unique array of
                hand-chosen accessories that aligns with the theme of the
                collection.{" "}
              </p>
              <p>
                This is the second collection in the Women Unite series, which
                retains the same core values for the betterment, empowerment and
                unity of womankind, while also introducing an element of fun,
                joy and quirkiness. Based on the lore, these 10,000 divine
                feminne souls were stuck in quarantine due to the pandemic and
                were unable to join forces with their fellow sisters in the WU
                Kingdom. Thus some of their unique outfits are derived from the
                fact that they have not seen the light of day in what seemed
                like forever. Nonetheless they were finally freed from lockdown
                and have gathered at the WU kingdom. There they received the
                instructions to continuously circle the WU palace for 90 days in
                order to summon a tribe of forgotten warriors.
              </p>
            </div>
            <div className="col-md-12 col-lg-6 text-center">
              <div className="video-frame-about mt-0">
                <img
                  src="images/wu-10k.jpg"
                  alt=""
                  title=""
                  className="img-fluid
                  imgframeset"
                />
                <div className="iframe-video-bg">
                  <img
                    src="images/video-frame-about.png"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <a
                href="https://opensea.io/collection/women-unite-10k-assemble"
                target={"_blank"}
                rel="noreferrer"
                className="btn about-btn mt-5"
              >
                VIEW 10K COLLECTION
              </a>
            </div>
          </div>

          <hr />

          <div className="row mb-5">
            <div className="col-md-12 col-lg-6">
              <div className="sub-title">
                Women Unite - The Forgotten Warriors Collection
              </div>
              <p>
                This is the 3rd Women Unite collection, Women Unite - The
                Forgotten Warriors Collection that will follow suit after the
                2nd collection Women Unite 10K collection.
              </p>
              <p>
                This collection will take place in The City of Angels, LA, and
                will unveil even more opportunities and benefits to receive even
                more abundance!{" "}
              </p>
              <a className="btn clickable for-desktop">
                Collection coming soon!
              </a>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="video-frame-about mt-0">
                <img
                  src="images/forgotten-warriors.jpg"
                  alt=""
                  title=""
                  className="img-fluid imgframeset"
                />
                <div className="iframe-video-bg">
                  <img
                    src="images/video-frame-about.png"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <a className="btn clickable for-mobile mt-4">
                Collection coming soon!
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Collections;
