import React, { useEffect, useState } from "react";
import { routesList } from "../Common";
import useWalletChange from "../hooks/useWalletChange";

const Members = () => {
  const { isWalletConnected, changedAddress } = useWalletChange();
  return (
    <>
      <section className="innner-banner login members">
        <div className="container">
          <div className="ban-canvas">
            <div className="big-title">Members Area</div>
          </div>
        </div>
      </section>

      <section className="login-btn section-pd">
        <div className="container text-center">
          <div className="sub-title">
            Access to the Virtual Women Unite Kingdom and all its treasures will
            be coming soon!{" "}
          </div>
          <p>
            Your Wallet Address shall serve as your royal key to enter the WU
            Kingdom
          </p>
          {/* <a href="#" className="btn">LOGIN WITH METAMASK</a>  */}
          <a href={routesList.indexPage} className="btn">
            Coming Soon
          </a>
        </div>
      </section>
    </>
  );
};

export default Members;
