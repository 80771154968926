import React, { Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";
import { FeedCard } from "./FeedCard";
import { SlickSlider } from "../Common/SlickSlider";

export const InstagramFeed = React.forwardRef(
  ({ access_token, limit, ...rest }, ref) => {
    const [feedsList, setFeedsList] = useState([]);
    const [pagingDetails, setPagingDetails] = useState({});
    const [baseUrl, setBaseUrl] = useState(``);
    const [currentPage, setCurrentPage] = useState(1);
    const tokenRef = useRef();

    useEffect(() => {
      if (tokenRef.current) {
        tokenRef.current = access_token;
      }
      setBaseUrl(
        `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption,permalink,thumbnail_url,username&limit=${limit}&access_token=${access_token}`
      );
    }, [access_token]);

    useEffect(() => {
      const abortRequest = new AbortController();
      if (baseUrl !== "") getIntagramFeeds();

      return () => {
        abortRequest.abort();
      };
    }, [baseUrl]);

    const getIntagramFeeds = async () => {
      try {
        axios.get(baseUrl).then((response) => {
          // console.log("Response: ", response);
          setFeedsList(response?.data?.data);
          setPagingDetails(response?.data?.paging);
        });
      } catch (error) {
        console.log("Error Fetching Instagram Feeds");
      }
    };

    return (
      <>
        <section className="womanRow">
          <SlickSlider
            {...{
              slidesToShow: 5,
              slidesToScroll: 1,
              autoplay: true,
              draggable: true,
              infinite: true,
              autoplaySpeed: 0,
              speed: 6000,
              pauseOnHover: true,
              arrows: false,
              cssEase: "linear",
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
              ],
            }}
          >
            {feedsList.map((feed, idx) => {
              return (
                <div key={idx}>
                  <FeedCard key={feed.id} {...feed} />
                </div>
              );
            })}
          </SlickSlider>
          <div className="wu-carousel-home"></div>
        </section>
      </>
    );
  }
);
