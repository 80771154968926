/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  labels,
  metamaskErrorMessages,
  notificationPosition,
  notificationSetting,
  notificationType,
  routesList,
  showNotification,
} from "../Common";
import { connectWallet } from "../Scripts/interact";
import useWalletChange from "../hooks/useWalletChange";

const Header = () => {
  const { isWalletConnected, changedAddress } = useWalletChange();
  const history = useLocation();
  const connectWalletPressed = async () => {
    // console.log("Entered in Function");
    const response = await connectWallet();

    if (response.status) {
      console.log("Wallet Connected");
    } else {
      showNotification(
        notificationType.DANGER,
        response.message,
        notificationSetting
      );
    }
  };

  const appendActiveClass = (path) => {
    if (history && history.pathname === path) {
      return ` active`;
    }
    return ``;
  };
  return (
    <>
      <header>
        <div className="top-icon for-desktop">
          <div className="container">
            <ul className="social-icon top-head">
              <li>
                <a
                  href="https://www.youtube.com/channel/UCbC26jObRCXZ7nEFhz5CRCw"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-youtube"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/womenunitenft/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://discord.com/invite/MG7C59rBwH"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-discord"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/WomenUniteNFT"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://medium.com/@womenunitenft"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-medium"></i>
                </a>
              </li>
            </ul>
            {/* <div className="mintbtn">
              {!isWalletConnected ? (
                <a onClick={connectWalletPressed} className="btn">
                  LOGIN WITH METAMASK
                </a>
              ) : (
                isWalletConnected && (
                  <Link to={routesList.mint} className="btn">
                    MINT NFT
                  </Link>
                )
              )}
            </div> */}
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
              <i className="bi bi-x-lg"></i>
            </button>
            <Link className="navbar-brand for-mobile" to={routesList.indexPage}>
              <img src="images/logo.png" alt="" width="250" />
            </Link>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="https://opensea.io/collection/womenunite"
                    target="_blank"
                    rel="noreferrer"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Buy a token
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        target="_blank"
                        rel="noreferrer"
                        href="https://opensea.io/collection/womenunite"
                      >
                        Original collection
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        target="_blank"
                        rel="noreferrer"
                        href="https://opensea.io/collection/women-unite-10k-assemble"
                      >
                        10k assemble
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link${appendActiveClass(
                      routesList.roadmap
                    )}`}
                    to={routesList.roadmap}
                  >
                    Roadmap
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link${appendActiveClass(
                      routesList.about
                    )}`} to={routesList.about}>
                    About Us
                  </Link>
                </li>
                {/* <li className="nav-item dropdown">
                  <a
                    className={`nav-link dropdown-toggle clickable`}
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    About
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link to={routesList.about} className="dropdown-item">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={routesList.collections}
                        className="dropdown-item"
                      >
                        About Collections
                      </Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
              <Link
                className="navbar-brand for-desktop"
                to={routesList.indexPage}
              >
                <img src="images/logo.png" alt="" width="250" />
              </Link>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className={`nav-link${appendActiveClass(routesList.team)}`}
                    to={routesList.team}
                  >
                    The Team
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link${appendActiveClass(
                      routesList.collections
                    )}`} to={routesList.collections}>
                    Collections
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link${appendActiveClass(
                      routesList.infographic
                    )}`} to={routesList.infographic} target="_blank">
                    Gamification
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className={`nav-link${appendActiveClass(
                      routesList.provenance
                    )}`}
                    to={routesList.provenance}
                  >
                    Provenance
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link${appendActiveClass(
                      routesList.members
                    )}`}
                    to={routesList.members}
                  >
                    Members
                  </Link>
                </li> */}
              </ul>
              <div className="top-icon for-mobile">
                <ul className="social-icon top-head">
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCbC26jObRCXZ7nEFhz5CRCw"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/womenunitenft/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://discord.com/invite/MG7C59rBwH"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-discord"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/WomenUniteNFT"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-twitter"></i>
                    </a>
                  </li>
                </ul>
                {/* <div className="mintbtn">
                  {!isWalletConnected ? (
                    <a onClick={connectWalletPressed} className="btn">
                      LOGIN WITH METAMASK
                    </a>
                  ) : (
                    isWalletConnected && (
                      <Link to={routesList.mint} className="btn">
                        MINT NFT
                      </Link>
                    )
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
