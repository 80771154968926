/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import {
  checkBlank,
  checkExist,
  loaderType,
  metamaskErrorMessages,
  notificationSetting,
  notificationType,
  showNotification,
  StartLoader,
  StopLoader,
} from "../Common";
import { GlobalInput } from "../Global/GlobalInput";
import {
  getApproved,
  getBalanceOf,
  getGoldMinted,
  getOwnerOf,
  getSilverMinted,
  getSupportsInterfaceStatus,
  getTeamListed,
  getTokenByIndex,
  getTokenOfOwnerByIndex,
  getTokenURI,
  getWalletOfOwner,
  isApprovedForAll,
  isWhitelisted,
} from "../Scripts/interact";
import { MintButton } from "../SubSection";
import NoData from "../SubSection/NoData";

export const ReadFunctions = React.forwardRef(
  (
    {
      price,
      baseExtension,
      baseURI,
      maxGenCount,
      maxPerMint,
      maxMints,
      contractName,
      notRevealedURI,
      ownerAddress,
      paused,
      privateSale,
      publicSale,
      renounceOwnership,
      revealed,
      symbol,
      totalSupply,
      thronePassSale,
    },
    ref
  ) => {
    const [form, setForm] = useState({});

    const getBalanceOfFromContract = async () => {
      StartLoader(loaderType.MINT);
      let setBalance = "";
      setForm((form) => {
        return { ...form, balance: "" };
      });
      const response = await getBalanceOf(form?.balanceOfInput);
      //console.log("response", response);
      if (response?.status) {
        StopLoader(loaderType.MINT);
        setBalance = response?.balance;
      } else {
        StopLoader(loaderType.MINT);
        showNotification(
          notificationType.DANGER,
          `${metamaskErrorMessages.canNotGetError}Balance ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
          notificationSetting
        );
      }
      setForm((form) => {
        return { ...form, balance: setBalance };
      });
    };

    const getApprovedFromContract = async () => {
      StartLoader(loaderType.MINT);
      let setApproved = "";
      setForm((form) => {
        return { ...form, approvedAddress: "" };
      });
      const response = await getApproved(form?.getApprovedInput);
      //console.log("response", response);
      if (response?.status) {
        StopLoader(loaderType.MINT);
        setApproved = response?.address;
      } else {
        StopLoader(loaderType.MINT);
        showNotification(
          notificationType.DANGER,
          `${metamaskErrorMessages.canNotGetError}Approved ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
          notificationSetting
        );
      }
      setForm((form) => {
        return { ...form, approvedAddress: setApproved };
      });
    };

    const getApprovedForAllFromContract = async () => {
      StartLoader(loaderType.MINT);
      let setApprovedForAll = "";
      setForm((form) => {
        return { ...form, approvedAddress: "" };
      });
      const response = await isApprovedForAll(
        form?.approvedForAllOwnerAddress,
        form?.approvedForAllOperatorAddress
      );
      // console.log("response", response);
      if (response?.status) {
        StopLoader(loaderType.MINT);
        setApprovedForAll = response?.isApprovedForAll;
      } else {
        StopLoader(loaderType.MINT);
        showNotification(
          notificationType.DANGER,
          `${metamaskErrorMessages.canNotGetError}Approved ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
          notificationSetting
        );
      }
      setForm((form) => {
        return { ...form, isApprovedForAll: setApprovedForAll };
      });
    };

    const getOwnerOfFromContract = async () => {
      StartLoader(loaderType.MINT);
      let setOwnerOf = "";
      setForm((form) => {
        return { ...form, ownerOf: "" };
      });
      const response = await getOwnerOf(form?.ownerOfInput);
      //console.log("response", response);
      if (response?.status) {
        StopLoader(loaderType.MINT);
        setOwnerOf = response?.ownerOfToken;
      } else {
        StopLoader(loaderType.MINT);
        showNotification(
          notificationType.DANGER,
          `${metamaskErrorMessages.canNotGetError}Owner ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
          notificationSetting
        );
      }
      setForm((form) => {
        return { ...form, ownerOf: setOwnerOf };
      });
    };

    // const getSupportsInterfaceFromContract = async () => {
    //   const response = await getSupportsInterfaceStatus(form?.interfaceIdInput);
    //   //console.log("response", response);
    //   if (response?.status) {
    //     setForm({
    //       ...form,
    //       supportsInterface: response?.supportsInterfaceStatus,
    //     });
    //   } else {
    //     showNotification(
    //       notificationType.DANGER,
    //       response?.error.message,
    //       notificationSetting
    //     );
    //   }
    // };

    const getTeamListedFromContract = async () => {
      StartLoader(loaderType.MINT);
      let setTeamListed = "";
      setForm((form) => {
        return { ...form, teamListed: "" };
      });
      const response = await getTeamListed(form?.teamListedInput);
      // console.log("response", response);
      if (response?.status) {
        StopLoader(loaderType.MINT);
        setTeamListed = response?.teamListed;
      } else {
        StopLoader(loaderType.MINT);
        showNotification(
          notificationType.DANGER,
          `${metamaskErrorMessages.canNotGetError}Teamlisted ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
          notificationSetting
        );
      }
      setForm((form) => {
        return { ...form, teamListed: setTeamListed };
      });
    };

    const getTokenByIndexFromContract = async () => {
      StartLoader(loaderType.MINT);
      let setTokenByIndex = "";
      setForm((form) => {
        return { ...form, tokenByIndex: "" };
      });
      const response = await getTokenByIndex(form?.tokenByIndexInput);
      // console.log("response", response);
      if (response?.status) {
        StopLoader(loaderType.MINT);
        setTokenByIndex = response?.tokenByIndex;
      } else {
        StopLoader(loaderType.MINT);
        showNotification(
          notificationType.DANGER,
          `${metamaskErrorMessages.canNotGetError}Token ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
          notificationSetting
        );
      }
      setForm((form) => {
        return { ...form, tokenByIndex: setTokenByIndex };
      });
    };

    const getTokenOfOwnerIndexFromContract = async () => {
      StartLoader(loaderType.MINT);
      let setTokenOfOwnerByIndex = "";
      setForm((form) => {
        return { ...form, tokenOfOwnerByIndex: "" };
      });
      const response = await getTokenOfOwnerByIndex(
        form?.tokenOfOwnerAddress,
        form?.tokenOfOwnerIndex
      );
      // console.log("response", response);
      if (response?.status) {
        StopLoader(loaderType.MINT);
        setTokenOfOwnerByIndex = response?.tokenOfOwnerByIndex;
      } else {
        StopLoader(loaderType.MINT);
        showNotification(
          notificationType.DANGER,
          `${metamaskErrorMessages.canNotGetError}Token ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
          notificationSetting
        );
      }
      setForm((form) => {
        return { ...form, tokenOfOwnerByIndex: setTokenOfOwnerByIndex };
      });
    };

    const getTokenURIFromContract = async () => {
      StartLoader(loaderType.MINT);
      let setTokenURI = "";
      setForm((form) => {
        return { ...form, tokenURI: "" };
      });
      const response = await getTokenURI(form?.tokenUriInput);
      // console.log("response", response);
      if (response?.status) {
        StopLoader(loaderType.MINT);
        setTokenURI = response?.tokenURI;
      } else {
        StopLoader(loaderType.MINT);
        showNotification(
          notificationType.DANGER,
          `${metamaskErrorMessages.canNotGetError}Token URI ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
          notificationSetting
        );
      }
      setForm((form) => {
        return { ...form, tokenURI: setTokenURI };
      });
    };

    const getWalletOfOwnerFromContract = async () => {
      StartLoader(loaderType.MINT);
      let setWalletOfOwnerArray = "";
      setForm((form) => {
        return { ...form, walletOfOwnerArray: "" };
      });
      const response = await getWalletOfOwner(form?.walletOfOwnerInput);
      // console.log("response", response);
      if (response?.status) {
        StopLoader(loaderType.MINT);
        setWalletOfOwnerArray =
          response?.walletOfOwnerArray.length > 0
            ? response?.walletOfOwnerArray.toString()
            : "";
      } else {
        StopLoader(loaderType.MINT);
        showNotification(
          notificationType.DANGER,
          `${metamaskErrorMessages.canNotGetError}Wallet Details ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
          notificationSetting
        );
      }
      setForm((form) => {
        return { ...form, walletOfOwnerArray: setWalletOfOwnerArray };
      });
    };

    const getWhitelistedStatusFromContract = async () => {
      StartLoader(loaderType.MINT);
      let setWhitelisted = "";
      setForm((form) => {
        return { ...form, whitelisted: "" };
      });
      const response = await isWhitelisted(form?.whitelistedInput);
      // console.log("response", response);
      if (response?.status) {
        StopLoader(loaderType.MINT);
        setWhitelisted = response?.whitelisted;
      } else {
        StopLoader(loaderType.MINT);
        showNotification(
          notificationType.DANGER,
          `${metamaskErrorMessages.canNotGetError}Whitelisted Status ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
          notificationSetting
        );
      }
      setForm((form) => {
        return { ...form, whitelisted: setWhitelisted };
      });
    };

    const getGoldMintedFromContract = async () => {
      StartLoader(loaderType.MINT);
      let setGoldMinted = "";
      setForm((form) => {
        return { ...form, goldMinted: "" };
      });
      const response = await getGoldMinted(form?.goldMintedInput);
      if (response?.status) {
        StopLoader(loaderType.MINT);
        setGoldMinted = response?.goldMinted;
      } else {
        StopLoader(loaderType.MINT);
        showNotification(
          notificationType.DANGER,
          `${metamaskErrorMessages.canNotGetError}Gold Minted ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
          notificationSetting
        );
      }
      setForm((form) => {
        return { ...form, goldMinted: setGoldMinted };
      });
    };

    const getSilverMintedFromContract = async () => {
      StartLoader(loaderType.MINT);
      let setSilverMinted = "";
      setForm((form) => {
        return { ...form, silverMinted: "" };
      });
      const response = await getSilverMinted(form?.silverMintedInput);
      if (response?.status) {
        StopLoader(loaderType.MINT);
        setSilverMinted = response?.silverMinted;
      } else {
        StopLoader(loaderType.MINT);
        showNotification(
          notificationType.DANGER,
          `${metamaskErrorMessages.canNotGetError}Silver Minted ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
          notificationSetting
        );
      }
      setForm((form) => {
        return { ...form, silverMinted: setSilverMinted };
      });
    };

    //Setter Functions
    const handleInputChange = (event, data) => {
      setForm({ ...form, [data.name]: data.value });
    };
    // console.log(checkBlank(form?.walletOfOwnerArray));
    // console.log(checkExist(form?.walletOfOwnerArray));
    return (
      <>
        <Accordion defaultActiveKey="price">
          <Accordion.Item eventKey="price">
            <Accordion.Header>1. price</Accordion.Header>
            <Accordion.Body>
              {checkExist(price) ? (
                <span>
                  <a
                    href={`https://etherscan.io/unitconverter?wei=${price}`}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {price}
                  </a>
                  &nbsp;<span className="dataType">uint256</span>
                </span>
              ) : (
                <NoData />
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="balanceOf">
            <Accordion.Header>2. balanceOf</Accordion.Header>
            <Accordion.Body>
              <h6 className="mb-3">{`See {IERC721-balanceOf}.`}</h6>

              <GlobalInput
                label={"owner (address)"}
                placeholder={"owner (address)"}
                id={"balanceOfInput"}
                name={"balanceOfInput"}
                value={form?.balanceOfInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={getBalanceOfFromContract}
              >
                Query!
              </MintButton>
              {checkExist(form?.balance) && (
                <>
                  <hr />
                  <span>Balance: {form?.balance}</span>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="baseURI">
            <Accordion.Header>3. baseURI</Accordion.Header>
            <Accordion.Body>
              {checkExist(baseURI) ? (
                <span>
                  {baseURI}&nbsp;<span className="dataType">string</span>
                </span>
              ) : (
                <NoData />
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="thronePassSale">
            <Accordion.Header>4. thronePassSale</Accordion.Header>
            <Accordion.Body>
              {checkExist(thronePassSale) ? (
                <span>
                  {`Current Type: ${thronePassSale}`}&nbsp;
                  <span className="dataType">uint256</span>
                </span>
              ) : (
                <NoData />
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="privateSale">
            <Accordion.Header>5. privateSale</Accordion.Header>
            <Accordion.Body>
              {checkExist(privateSale) ? (
                <span>
                  {`Current Status: ${privateSale ? "true" : "false"}`}&nbsp;
                  <span className="dataType">bool</span>
                </span>
              ) : (
                <NoData />
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="publicSale">
            <Accordion.Header>6. publicSale</Accordion.Header>
            <Accordion.Body>
              {checkExist(publicSale) ? (
                <span>
                  {`Current Status: ${publicSale ? "true" : "false"}`}&nbsp;
                  <span className="dataType">bool</span>
                </span>
              ) : (
                <NoData />
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="goldMinted">
            <Accordion.Header>7. goldMinted</Accordion.Header>
            <Accordion.Body>
              <GlobalInput
                label={"<input> (address)"}
                placeholder={"<input> (address)"}
                id={"goldMintedInput"}
                name={"goldMintedInput"}
                value={form?.goldMintedInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={getGoldMintedFromContract}
              >
                Query!
              </MintButton>
              {checkExist(form?.goldMinted) && (
                <>
                  <hr />
                  <span>GoldMinted: {form?.goldMinted}</span>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="silverMinted">
            <Accordion.Header>8. silverMinted</Accordion.Header>
            <Accordion.Body>
              <GlobalInput
                label={"<input> (address)"}
                placeholder={"<input> (address)"}
                id={"silverMintedInput"}
                name={"silverMintedInput"}
                value={form?.silverMintedInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={getSilverMintedFromContract}
              >
                Query!
              </MintButton>
              {checkExist(form?.silverMinted) && (
                <>
                  <hr />
                  <span>SilverMinted: {form?.silverMinted}</span>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="maxPerMintTokens">
            <Accordion.Header>9. maxPerMintTokens</Accordion.Header>
            <Accordion.Body>
              {checkExist(maxPerMint) ? (
                <span>
                  {maxPerMint}&nbsp;
                  <span className="dataType">uint256</span>
                </span>
              ) : (
                <NoData />
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="maxGenCount">
            <Accordion.Header>10. maxGenCount</Accordion.Header>
            <Accordion.Body>
              {checkExist(maxGenCount) ? (
                <span>
                  {maxGenCount}&nbsp;
                  <span className="dataType">uint256</span>
                </span>
              ) : (
                <NoData />
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="totalSupply">
            <Accordion.Header>11. totalSupply</Accordion.Header>
            <Accordion.Body>
              {checkExist(totalSupply) ? (
                <span>
                  {totalSupply}&nbsp;
                  <span className="dataType">uint256</span>
                </span>
              ) : (
                <NoData />
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="owner">
            <Accordion.Header>12. owner</Accordion.Header>
            <Accordion.Body>
              {checkExist(totalSupply) ? (
                <>
                  <h6 className="mb-3">{`Returns the address of the current owner.`}</h6>
                  <span>
                    <a
                      href={`https://etherscan.io/address/${ownerAddress}`}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {ownerAddress}
                    </a>
                    &nbsp;
                    <span className="dataType">address</span>
                  </span>
                </>
              ) : (
                <NoData />
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="walletOfOwner">
            <Accordion.Header>13. walletOfOwner</Accordion.Header>
            <Accordion.Body>
              <GlobalInput
                label={"_owner (address)"}
                placeholder={"_owner (address)"}
                id={"walletOfOwnerInput"}
                name={"walletOfOwnerInput"}
                value={form?.walletOfOwnerInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={getWalletOfOwnerFromContract}
              >
                Query!
              </MintButton>
              {checkBlank(form?.walletOfOwnerArray) ? (
                <>
                  <hr />
                  <span>You Have Not Minted Yet.</span>
                </>
              ) : (
                checkExist(form?.walletOfOwnerArray) && (
                  <>
                    <hr />
                    <span>
                      Wallet(tokens): {`[${form?.walletOfOwnerArray}]`}
                    </span>
                  </>
                )
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="whitelisted">
            <Accordion.Header>14. whitelisted</Accordion.Header>
            <Accordion.Body>
              <GlobalInput
                label={"<input> (address)"}
                placeholder={"<input> (address)"}
                id={"whitelistedInput"}
                name={"whitelistedInput"}
                value={form?.whitelistedInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={getWhitelistedStatusFromContract}
              >
                Query!
              </MintButton>
              {checkExist(form?.whitelisted) && (
                <>
                  <hr />
                  <span>
                    Whitelisted: {form?.whitelisted ? "true" : "false"}
                  </span>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="paused">
            <Accordion.Header>15. paused</Accordion.Header>
            <Accordion.Body>
              {checkExist(paused) ? (
                <span>
                  {`Current Status: ${paused ? "true" : "false"}`}&nbsp;
                  <span className="dataType">bool</span>
                </span>
              ) : (
                <NoData />
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="name">
            <Accordion.Header>16. name</Accordion.Header>
            <Accordion.Body>
              {checkExist(contractName) ? (
                <>
                  <h6 className="mb-3">{`See {IERC721Metadata-name}.`}</h6>
                  <span>
                    {contractName}&nbsp;
                    <span className="dataType">string</span>
                  </span>
                </>
              ) : (
                <NoData />
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="ownerOf">
            <Accordion.Header>17. ownerOf</Accordion.Header>
            <Accordion.Body>
              <h6 className="mb-3">{`See {IERC721-ownerOf}.`}</h6>
              <GlobalInput
                label={"tokenId (uint256)"}
                placeholder={"tokenId (uint256)"}
                id={"ownerOfInput"}
                name={"ownerOfInput"}
                value={form?.ownerOfInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={getOwnerOfFromContract}
              >
                Query!
              </MintButton>
              {checkExist(form?.ownerOf) && (
                <>
                  <hr />
                  <span>Onwer's Address: {form?.ownerOf}</span>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="tokenByIndex">
            <Accordion.Header>18. tokenByIndex</Accordion.Header>
            <Accordion.Body>
              <h6 className="mb-3">{`See {IERC165-supportsInterface}.`}</h6>
              <GlobalInput
                label={"index (uint256)"}
                placeholder={"index (uint256)"}
                id={"tokenByIndexInput"}
                name={"tokenByIndexInput"}
                value={form?.tokenByIndexInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={getTokenByIndexFromContract}
              >
                Query!
              </MintButton>
              {checkExist(form?.tokenByIndex) && (
                <>
                  <hr />
                  <span>Token By Index: {form?.tokenByIndex}</span>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="tokenOfOwnerByIndex">
            <Accordion.Header>19. tokenOfOwnerByIndex</Accordion.Header>
            <Accordion.Body>
              <div className="mb-4">
                <GlobalInput
                  label={"owner (address)"}
                  placeholder={"owner (address)"}
                  id={"tokenOfOwnerAddress"}
                  name={"tokenOfOwnerAddress"}
                  value={form?.tokenOfOwnerAddress}
                  onChange={(event, data) => handleInputChange(event, data)}
                />
              </div>

              <GlobalInput
                label={"index (uint256)"}
                placeholder={"index (uint256)"}
                id={"tokenOfOwnerIndex"}
                name={"tokenOfOwnerIndex"}
                value={form?.tokenOfOwnerIndex}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={getTokenOfOwnerIndexFromContract}
              >
                Query!
              </MintButton>
              {checkExist(form?.tokenOfOwnerByIndex) && (
                <>
                  <hr />
                  <span>
                    Token of Owner By Index: {form?.tokenOfOwnerByIndex}
                  </span>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="tokenURI">
            <Accordion.Header>20. tokenURI</Accordion.Header>
            <Accordion.Body>
              <h6 className="mb-3">{`See {IERC165-supportsInterface}.`}</h6>

              <GlobalInput
                label={"_tokenId (uint256)"}
                placeholder={"_tokenId (uint256)"}
                id={"tokenUriInput"}
                name={"tokenUriInput"}
                value={form?.tokenUriInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={getTokenURIFromContract}
              >
                Query!
              </MintButton>
              {checkExist(form?.tokenURI) && (
                <>
                  <hr />
                  <span>Token URI: {form?.tokenURI}</span>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="revealed">
            <Accordion.Header>21. revealed</Accordion.Header>
            <Accordion.Body>
              {checkExist(revealed) ? (
                <span>
                  {`Current Status: ${revealed ? "true" : "false"}`}&nbsp;
                  <span className="dataType">bool</span>
                </span>
              ) : (
                <NoData />
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="getApproved">
            <Accordion.Header>22. getApproved</Accordion.Header>
            <Accordion.Body>
              <h6 className="mb-3">{`See {IERC721-getApproved}.`}</h6>

              <GlobalInput
                label={"tokenId (uint256)"}
                placeholder={"tokenId (uint256)"}
                id={"getApprovedInput"}
                name={"getApprovedInput"}
                value={form?.getApprovedInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={getApprovedFromContract}
              >
                Query!
              </MintButton>
              {checkExist(form?.approvedAddress) && (
                <>
                  <hr />
                  <span>Approved Address: {form?.approvedAddress}</span>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="isApprovedForAll">
            <Accordion.Header>23. isApprovedForAll</Accordion.Header>
            <Accordion.Body>
              <div className="mb-4">
                <GlobalInput
                  label={"owner (address)"}
                  placeholder={"owner (address)"}
                  id={"approvedForAllOwnerAddress"}
                  name={"approvedForAllOwnerAddress"}
                  value={form?.approvedForAllOwnerAddress}
                  onChange={(event, data) => handleInputChange(event, data)}
                />
              </div>

              <GlobalInput
                label={"operator (address)"}
                placeholder={"operator (address)"}
                id={"approvedForAllOperatorAddress"}
                name={"approvedForAllOperatorAddress"}
                value={form?.approvedForAllOperatorAddress}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={getApprovedForAllFromContract}
              >
                Query!
              </MintButton>
              {checkExist(form?.isApprovedForAll) && (
                <>
                  <hr />
                  <span>
                    Approved: {form?.isApprovedForAll ? "true" : "false"}
                  </span>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="notRevealedUri">
            <Accordion.Header>24. notRevealedUri</Accordion.Header>
            <Accordion.Body>
              {checkExist(notRevealedURI) ? (
                <span>
                  {notRevealedURI}&nbsp;
                  <span className="dataType">string</span>
                </span>
              ) : (
                <NoData />
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="symbol">
            <Accordion.Header>25. symbol</Accordion.Header>
            <Accordion.Body>
              {checkExist(notRevealedURI) ? (
                <>
                  <h6 className="mb-3">{`See {IERC721Metadata-symbol}.`}</h6>
                  <span>
                    {symbol}&nbsp;<span className="dataType">string</span>
                  </span>
                </>
              ) : (
                <NoData />
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="teamListed">
            <Accordion.Header>26. teamListed</Accordion.Header>
            <Accordion.Body>
              <GlobalInput
                label={"<input> (address)"}
                placeholder={"<input> (address)"}
                id={"teamListedInput"}
                name={"teamListedInput"}
                value={form?.teamListedInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={getTeamListedFromContract}
              >
                Query!
              </MintButton>
              {checkExist(form?.teamListed) && (
                <>
                  <hr />
                  <span>TeamListed: {form?.teamListed}</span>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="baseExtension">
            <Accordion.Header>27. baseExtension</Accordion.Header>
            <Accordion.Body>
              {checkExist(baseExtension) ? (
                <span>
                  {baseExtension}&nbsp;
                  <span className="dataType">uint256</span>
                </span>
              ) : (
                <NoData />
              )}
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="maxSupply">
            <Accordion.Header>maxSupply</Accordion.Header>
            <Accordion.Body>
              <span>
                {maxMints}&nbsp;<span className="dataType">uint256</span>
              </span>
            </Accordion.Body>
          </Accordion.Item> 
          <Accordion.Item eventKey="supportsInterface">
            <Accordion.Header>supportsInterface</Accordion.Header>
            <Accordion.Body>
              <h6 className="mb-3">{`See {IERC165-supportsInterface}.`}</h6>
              
              <GlobalInput
                label={"interfaceId (bytes4)"}
                placeholder={"interfaceId (bytes4)"}
                id={"interfaceIdInput"}
                name={"interfaceIdInput"}
                value={form?.interfaceIdInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={getSupportsInterfaceFromContract}
              >
                Query!
              </MintButton>
            </Accordion.Body>
          </Accordion.Item> 
          <Accordion.Item eventKey="renounceOwnership">
            <Accordion.Header>renounceOwnership</Accordion.Header>
            <Accordion.Body>
              <span>
                {renounceOwnership}&nbsp;
                <span className="dataType">uint256</span>
              </span>
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>
      </>
    );
  }
);
