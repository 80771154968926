import React from "react";

export const FeedCard = React.forwardRef(
  ({ caption, id, media_type, media_url, permalink, ...rest }, ref) => {
    let feed;
    switch (media_type) {
      case "CAROUSEL_ALBUM":
        feed = (
          <a href={permalink} target="_">
            <img
              id={id}
              src={media_url}
              alt={caption}
            />
          </a>
        );
        break;
      case "VIDEO":
        feed = (
          <a href={permalink} target="_">
            <video
              id={id}
              className={"slick-video"}
              src={media_url}
              type="video/mp4"
              controls
              playsInline
            ></video>
          </a>
        );
        break;
      default:
        feed = (
          <a href={permalink} target="_">
            <img
              id={id}
              src={media_url}
              alt={caption}
            />
          </a>
        );
    }
    return <>{feed}</>;
  }
);
