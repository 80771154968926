import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 

export const SlickSlider = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <>
      <Slider {...props}>{children}</Slider>
    </>
  );
});
