import React, { useEffect, useState } from "react";
import useWalletChange from "../hooks/useWalletChange";
import { OwlSlider } from "../Common/OwlSlider";

const Team = () => {
  const { isWalletConnected, changedAddress } = useWalletChange();
  return (
    <>
      {/* Banner */}
      <section className="innner-banner team-banner">
        <div className="container">
          <div className="ban-canvas">
            {/* <h2>“You can be powerful, intelligent, beautiful, and loving at the
            same time. Let us unite and make this world a
            better place!” </h2>
          <span> - Miss Kaina</span> */}
            <div className="big-title">Team</div>
          </div>
        </div>
      </section>

      <section className="meet-team section-pt">
        <div className="container">
          <h2>Meet The WU Team</h2>
          <div className="sub-title mb-5">
            Meet the Models behind the project who have unified together to
            represent strength, beauty, and generosity to impact women in need
            globally.{" "}
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="card-team">
                <img className="team-img" src="images/team-1.jpg" alt="" />
                <div className="title1">Angela P. Quintero</div>
                <a
                  href="https://www.instagram.com/angelinap93/?hl=en"
                  className="team-name"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-instagram"></i>
                  ANGELINA93
                </a>
                <p>
                  First Latina (Colombian) NFT Model , Creative, Clumsy, Cheese
                  Lover, Yogini & Full Time Singer In The Shower.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="card-team">
                <img className="team-img" src="images/team-2.jpg" alt="" />
                <div className="title1">Aditi Dubey</div>
                <a
                  href="https://www.instagram.com/makeupbyaditi/?hl=en"
                  className="team-name"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-instagram"></i>
                  MAKEUPBYADITI
                </a>
                <p>
                  Influencer From India, Professional Makeup Artist, Model,
                  Creative, Yogini, Follow Her To See Her Crazy Aquarius Side
                  Come Out.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="card-team">
                <img className="team-img" src="images/team-3.jpg" alt="" />
                <div className="title1">Miss Kaina</div>
                <a
                  href="https://www.instagram.com/misskaina/?hl=en"
                  className="team-name"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-instagram"></i>
                  MISSKAINA
                </a>
                <p>
                  Amsterdam, Morocco & Maluku Girl, 6 Figure NFT Trader, Music,
                  Fashion Lover oh.. and CHOCOLATE!!!{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="card-team">
                <img className="team-img" src="images/team-4.jpg" alt="" />
                <div className="title1">Nittia Thaprakhon</div>
                <a
                  href="https://www.instagram.com/itsnittia/?hl=en"
                  className="team-name"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-instagram"></i>
                  ITSNITTIA
                </a>
                <p>
                  From Thailand To The U.K. On A Mission To Help Bridge The
                  Physical And Meta Physical. Spreading Love, Light, &
                  Positivity Across The Meta Space.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="card-team">
                <img className="team-img" src="images/team-5.jpg" alt="" />
                <div className="title1">Sabrina Tekeste Araya</div>
                <a
                  href="https://www.instagram.com/iamrinaraya/?hl=en"
                  className="team-name"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-instagram"></i>
                  IAMRINARAYA
                </a>
                <p>
                  Sabrina Is A German Girl With African Roots. Looks Cute But
                  Laughs Like A Maniac.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="card-team">
                <img className="team-img" src="images/team-6.jpg" alt="" />
                <div className="title1">Paulina Rzepka</div>
                <a
                  href="https://www.instagram.com/_justpoli/?hl=en"
                  className="team-name"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-instagram"></i>
                  _JUSTPOLI
                </a>
                <p>
                  Polish Poli believing in the making impossible possible.
                  Crazy. Determined. Living Life. Take a ride on her ENERGY.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonals */}
      {/* <section className="testimonals section-pd">
        <h2>Influencers Backing</h2>
        <OwlSlider
          className="testimonals-carousel"
          {...{
            center: true,
            stagePadding: 150,
            items: 2,
            autoplay: true,
            loop: true,
            margin: 30,
            dots: true,
            responsive: {
              1199: {
                items: 2,
              },
              600: {
                items: 1,
              },
              0: {
                items: 1,
                stagePadding: 30,
              },
            },
          }}
        >
          <div className="testm-item">
            <img src="images/wu1.jpg" alt="" title="" className="img-fluid" />
            <div className="testm-content">
              <p>
                "The best thing to happen to me in the most uncertain time of my
                life!"
              </p>
              <span className="testm-name">Leah Marville</span>
              <span className="testm-post">Attorney, Miss Barbados 2009</span>
            </div>
          </div>

          <div className="testm-item">
            <img src="images/wu2.jpg" alt="" title="" className="img-fluid" />
            <div className="testm-content">
              <p>
                "The best thing to happen to me in the most uncertain time of my
                life!"
              </p>
              <span className="testm-name">Leah Marville</span>
              <span className="testm-post">Attorney, Miss Barbados 2009</span>
            </div>
          </div>

          <div className="testm-item">
            <img src="images/wu3.jpg" alt="" title="" className="img-fluid" />
            <div className="testm-content">
              <p>
                "The best thing to happen to me in the most uncertain time of my
                life!"
              </p>
              <span className="testm-name">Leah Marville</span>
              <span className="testm-post">Attorney, Miss Barbados 2009</span>
            </div>
          </div>

          <div className="testm-item">
            <img src="images/wu4.jpg" alt="" title="" className="img-fluid" />
            <div className="testm-content">
              <p>
                "The best thing to happen to me in the most uncertain time of my
                life!"
              </p>
              <span className="testm-name">Leah Marville</span>
              <span className="testm-post">Attorney, Miss Barbados 2009</span>
            </div>
          </div>

          <div className="testm-item">
            <img src="images/wu5.jpg" alt="" title="" className="img-fluid" />
            <div className="testm-content">
              <p>
                "The best thing to happen to me in the most uncertain time of my
                life!"
              </p>
              <span className="testm-name">Leah Marville</span>
              <span className="testm-post">Attorney, Miss Barbados 2009</span>
            </div>
          </div>

          <div className="testm-item">
            <img src="images/wu6.jpg" alt="" title="" className="img-fluid" />
            <div className="testm-content">
              <p>
                "The best thing to happen to me in the most uncertain time of my
                life!"
              </p>
              <span className="testm-name">Leah Marville</span>
              <span className="testm-post">Attorney, Miss Barbados 2009</span>
            </div>
          </div>
        </OwlSlider>
      </section> */}

      {/* Creator Team */}
      {/* <section className="creatorTeam">
        <div className="container">
          <h2 className="text-center">
            <img src="images/ic-icon.png" alt="" /> Women Unite is powered by
            the I Am Creator Team
            <img src="images/ic-icon.png" alt="" />
          </h2>
          <div className="flex-box">
            <div className="cTeam-flex part1">
              <div className="cTeam-item">
                <span className="ic-image">
                  <img
                    src="images/acharya.jpg"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </span>
                <div className="cTeam-content">
                  <div className="cTeam-name">Acharya Varalakshmi</div>
                  <div className="cTeam-post">
                    Master Yogini, Spiritual Leader
                  </div>
                </div>
              </div>
              <div className="cTeam-item">
                <span className="ic-image">
                  <img
                    src="images/akars.jpg"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </span>
                <div className="cTeam-content">
                  <div className="cTeam-name">Master Sri Akarshana</div>
                  <div className="cTeam-post">Spiritual Master</div>
                </div>
              </div>
            </div>
            <div className="cTeam-flex">
              <div className="cTeam-item">
                <span className="ic-image">
                  <img
                    src="images/mike.jpg"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </span>
                <div className="cTeam-content">
                  <div className="cTeam-name">Mike Jones</div>
                  <div className="cTeam-post">Director</div>
                </div>
              </div>
              <div className="cTeam-item">
                <span className="ic-image">
                  <img
                    src="images/jane.jpg"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </span>
                <div className="cTeam-content">
                  <div className="cTeam-name">Jane Tsai</div>
                  <div className="cTeam-post">
                    International Speaker, Marketer, Community Manager & Yogini
                  </div>
                </div>
              </div>
              <div className="cTeam-item">
                <span className="ic-image">
                  <img
                    src="images/chetanbala.jpg"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </span>
                <div className="cTeam-content">
                  <div className="cTeam-name">Chetanbala Hijam</div>
                  <div className="cTeam-post">Digital Marketer</div>
                </div>
              </div>
              <div className="cTeam-item">
                <span className="ic-image">
                  <img
                    src="images/samantha.jpg"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </span>
                <div className="cTeam-content">
                  <div className="cTeam-name">Samantha Lairenlakpam</div>
                  <div className="cTeam-post">Sales Expert</div>
                </div>
              </div>
              <div className="cTeam-item">
                <span className="ic-image">
                  <img
                    src="images/nascia.jpg"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </span>
                <div className="cTeam-content">
                  <div className="cTeam-name">Nascia Pinyung Lee</div>
                  <div className="cTeam-post">
                    Head of Operations & Zen Practitioner
                  </div>
                </div>
              </div>
              <div className="cTeam-item">
                <span className="ic-image">
                  <img
                    src="images/giang.jpg"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </span>
                <div className="cTeam-content">
                  <div className="cTeam-name">Đỗ Hoàng Giang</div>
                  <div className="cTeam-post">Digital Marketer</div>
                </div>
              </div>
            </div>
            <div className="cTeam-flex">
              <div className="cTeam-item">
                <span className="ic-image">
                  <img
                    src="images/dhan-lairenlakpam.jpg"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </span>
                <div className="cTeam-content">
                  <div className="cTeam-name">Dhan Lairenlakpam</div>
                  <div className="cTeam-post">Marketing Manager</div>
                </div>
              </div>
              <div className="cTeam-item">
                <span className="ic-image">
                  <img
                    src="images/manh.jpg"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </span>
                <div className="cTeam-content">
                  <div className="cTeam-name">Manh Tu Le</div>
                  <div className="cTeam-post">
                    Marketing Expert & NFT Collector
                  </div>
                </div>
              </div>
              <div className="cTeam-item">
                <span className="ic-image">
                  <img
                    src="images/joshua.jpg"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </span>
                <div className="cTeam-content">
                  <div className="cTeam-name">Joshua Andre</div>
                  <div className="cTeam-post">
                    Metaphysicist & International Speaker
                  </div>
                </div>
              </div>
              <div className="cTeam-item">
                <span className="ic-image">
                  <img
                    src="images/talk.jpg"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </span>
                <div className="cTeam-content">
                  <div className="cTeam-name">Tak-chi Ho</div>
                  <div className="cTeam-post">Project Manager & Life Coach</div>
                </div>
              </div>
              <div className="cTeam-item">
                <span className="ic-image">
                  <img
                    src="images/steve.jpg"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </span>
                <div className="cTeam-content">
                  <div className="cTeam-name">Steve Gwilt</div>
                  <div className="cTeam-post">
                    Creative Director & Branding Wizard
                  </div>
                </div>
              </div>
              <div className="cTeam-item">
                <span className="ic-image">
                  <img
                    src="images/tim.jpg"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </span>
                <div className="cTeam-content">
                  <div className="cTeam-name">Tim Tjung</div>
                  <div className="cTeam-post">
                    Community & Social Media Manager
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* collaborate */}
      <section className="collaborate section-pd">
        <div className="container">
          <div className="text-center">
            <img src="images/multigirl.jpg" alt="" />
          </div>
          <div className="content-bottom">
            <h2>Want to collaborate with us?</h2>
            <p>
              WU collaborates with and supports emerging projects from around
              the globe. If you're an artist, nonprofit or brand looking to
              collaborate, reach out to us at{" "}
              <strong>womenunitenft@iamcreator.com</strong>{" "}
            </p>
            <a
              href="images/Women_Unite_Info_Pack.pdf"
              target="_blank"
              rel="noreferrer"
              className="btn"
            >
              DOWNLOAD WU INFO PACK
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
