import React, { useEffect, useState } from "react";
import { routesList } from "../Common";
import useWalletChange from "../hooks/useWalletChange";

const Provenance = () => {
  const { isWalletConnected, changedAddress } = useWalletChange();
  return (
    <>
      <section className="innner-banner login proven">
        <div className="container">
          <div className="ban-canvas">
            <div className="big-title">Provenance</div>
          </div>
        </div>
      </section>

      <section className="proven section-pd">
        <div className="container">
          {/* <h2>Women Unite Provenance Record</h2>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.</p>  */}
          <div className="text-center">
            <a href={routesList.indexPage} className="btn">
              Coming Soon
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Provenance;
