/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-distracting-elements */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { digitFormatter, routesList } from "../Common";
import { OwlSlider } from "../Common/OwlSlider";
import { SlickSlider } from "../Common/SlickSlider";
import useWalletChange from "../hooks/useWalletChange";
import { InstagramFeed } from "../SubSection";

const HomePage = () => {
  const { isWalletConnected, changedAddress } = useWalletChange();
  const [stats, setStats] = useState();
  const navigate = useNavigate()

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(
        `https://api.opensea.io/api/v1/collection/women-unite-10k-assemble/stats`
      )
      .then((response) => {
        // console.log("Response: ", response);
        if (response?.status === 200) {
          setStats(response?.data?.stats);
        }
      });
  };

  const redirectMap = (path)=> {
    navigate(path)
  }

  const App = () => (
    <div>
      {/* <YourSvg /> */}
    </div>
   );

  return (
    <>
      {/* Banner */}
      <section className="innner-banner home">
        <div className="container">
            <div className="sticker-home-new">
               <img className="sticker-girl" src="images/banner-girls.png" alt="" />
               <img className="sticker-home" onClick={()=>redirectMap(routesList.about)} src="images/sticker-home.png" alt="" />
            </div>
           
          {/* <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width="1191" height="768" viewBox="0 0 1191 768"><g ><image width="1191" height="768" xlinkHref="images/banner-girls.png"/><image onClick={()=>redirectMap(routesList.about)} className="clickable" width="250" height="156" xlinkHref="images/checkout_home.png"/></g></svg> */}
 
          <span className="checkout_home"></span>
        </div>
      </section>
      <div className="break-news">
        <div className="left-title">WU BREAKING NEWS: </div>
        {/* <div className="marquee-news">
          <div className="marquee-news-inside">
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
            <div className="marquee-clone">
            222 COLLECTION HAS <span className="color-red"> SOLD OUT </span> -
            COMING SOON 10K COLLECTION LAUNCH 8th April 2022 - WU JOINS FORCES
            WITH CHARITY
            </div>
          </div>
        </div> */}
        <marquee direction="left" scrollamount="5" loop="INFINITE">
          WOMEN UNITE MAKES $40K+DONATION TO WEA, Climate Resilient Farmers in
          INDIA - WU JOINS FORCES WITH CHARITY - 10K COLLECTION HAS LAUNCHED -
          222 COLLECTION HAS <span className="color-red"> SOLD OUT </span>{" "}
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; WOMEN UNITE MAKES $40K+DONATION TO
          WEA, Climate Resilient Farmers in INDIA - WU JOINS FORCES WITH CHARITY
          - 10K COLLECTION HAS LAUNCHED - 222 COLLECTION HAS{" "}
          <span className="color-red"> SOLD OUT </span> &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; WOMEN UNITE MAKES $40K+DONATION TO WEA, Climate
          Resilient Farmers in INDIA - WU JOINS FORCES WITH CHARITY - 10K
          COLLECTION HAS LAUNCHED - 222 COLLECTION HAS{" "}
          <span className="color-red"> SOLD OUT </span> &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; WOMEN UNITE MAKES $40K+DONATION TO WEA, Climate
          Resilient Farmers in INDIA - WU JOINS FORCES WITH CHARITY - 10K
          COLLECTION HAS LAUNCHED - 222 COLLECTION HAS{" "}
          <span className="color-red"> SOLD OUT </span> &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; WOMEN UNITE MAKES $40K+DONATION TO WEA, Climate
          Resilient Farmers in INDIA - WU JOINS FORCES WITH CHARITY - 10K
          COLLECTION HAS LAUNCHED - 222 COLLECTION HAS{" "}
          <span className="color-red"> SOLD OUT </span> &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; WOMEN UNITE MAKES $40K+DONATION TO WEA, Climate
          Resilient Farmers in INDIA - WU JOINS FORCES WITH CHARITY - 10K
          COLLECTION HAS LAUNCHED - 222 COLLECTION HAS{" "}
          <span className="color-red"> SOLD OUT </span> &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; WOMEN UNITE MAKES $40K+DONATION TO WEA, Climate
          Resilient Farmers in INDIA - WU JOINS FORCES WITH CHARITY - 10K
          COLLECTION HAS LAUNCHED - 222 COLLECTION HAS{" "}
          <span className="color-red"> SOLD OUT </span> &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; WOMEN UNITE MAKES $40K+DONATION TO WEA, Climate
          Resilient Farmers in INDIA - WU JOINS FORCES WITH CHARITY - 10K
          COLLECTION HAS LAUNCHED - 222 COLLECTION HAS{" "}
          <span className="color-red"> SOLD OUT </span> &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; WOMEN UNITE MAKES $40K+DONATION TO WEA, Climate
          Resilient Farmers in INDIA - WU JOINS FORCES WITH CHARITY - 10K
          COLLECTION HAS LAUNCHED - 222 COLLECTION HAS{" "}
          <span className="color-red"> SOLD OUT </span> &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; WOMEN UNITE MAKES $40K+DONATION TO WEA, Climate
          Resilient Farmers in INDIA - WU JOINS FORCES WITH CHARITY - 10K
          COLLECTION HAS LAUNCHED - 222 COLLECTION HAS{" "}
          <span className="color-red"> SOLD OUT </span>
        </marquee>
      </div>

      {/* <section className="white-listed section-pt mb-3">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-6">
              <div className="video-frame-about">
                <div className="video-frame outervimeo">
                  <iframe
                    src="https://player.vimeo.com/video/691889001?h=fb84d13f98&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameBorder={0}
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    title="WU Promo 8 April 001 - HD 1080p"
                  ></iframe>
                </div>
                <div className="iframe-video-bg">
                  <img
                    src="images/video-frame-about-1.png"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="wu-launch mt-3 text-center">
                <p>
                  {" "}
                  WU 10K ASSEMBLE COLLECTION OFFICIAL LAUNCH 8th April 2022{" "}
                </p>
                <a
                  href="https://opensea.io/collection/women-unite-10k-assemble"
                  target="_"
                  className="btn"
                >
                  VIEW 10K COLLECTION
                </a> 
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* About */}
      <section className="about-us section-pd">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h2 className="mb-3">WELCOME TO THE WU KINGDOM</h2>
              <p>
                Women Unite is a collection of hand-taken photos, of real women,
                from around the world, adorned with over 100+ individually
                selected accessories. These are the 'Women Unite' that flourish
                on the Ethereum blockchain. Your Women Unite Token also
                functions as a membership card, which grants you access to the
                exclusive WU Kingdom,
              </p>
              <img className="hand-icon" src="images/hand-icon.png" alt="" />
              <div className="did-know">
                <h3>Did you know?</h3>
                <p>
                  Women Unit Donates 10% of proceeds to projects that empower
                  women across the globe.
                </p>
              </div>
              <div className="charity-box">
                <img src="images/wea-logo.jpg" alt="" />
                In our first charity endeavor, we are filled with joy to be able
                to make this contribution of 20 ETH, over $40,000, towards the
                Women’s Earth Alliance (WEA). We believe that supporting women -
                led charities like WEA, will empower across the globe to develop
                a more sustainable future not only for themselves but for the
                whole global ecosystem. Only by equipping women to become strong
                and powerful leaders in their fields, can we truly unite to make
                a difference and create a positive impact in this world.
              </div>
            </div>
            <div className="col-lg-5 text-center">
              <ul className="about-images">
                <li>
                  <img src="images/about-1.jpg" alt="" />
                </li>
                <li>
                  <img src="images/about-2.jpg" alt="" />
                </li>
                <li>
                  <img src="images/about-3.jpg" alt="" />
                </li>
                <li>
                  <img src="images/about-4.jpg" alt="" />
                </li>
              </ul>
              <Link
                to={routesList.collections}
                className="btn about-btn"
                rel="noreferrer"
              >
                View Collections
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Thron-pass */}
      <section className="thron-pass section-pb">
        <div className="container">
          <div className="row">
            <div className="col-xl-7">
              <div className="row">
                <div className="col-lg-4 col-md-3 mt-5">
                  <img src="images/pass.png" className="pass-img" alt="" />
                </div>
                <div className="col-lg-8 col-md-9">
                  <h2 className="mb-3">Throne Passes Benefits</h2>
                  <p>
                    The Women Unite Throne Pass is a unique all-access pass into
                    the WU Kingdom. It is the only way to gain access to the
                    whitelist for all future WU collections, which allows
                    holders to be able to mint tokens for FREE. There are 2
                    Types of Throne Passes, The Silver Pass [2000 Limit] and the
                    Gold Pass [222 Limit].{" "}
                  </p>
                  <p>
                    The Silver Pass grants access to the WU Kingdom and allows
                    holders to mint high rarity tokens of each future WU
                    collection. The Gold Pass grants access to the WU Kingdom
                    and allows holders to mint the top most rare tokens of each
                    future WU collection.
                  </p>
                  <p>
                    There is mention of the coveted Diamond Throne Pass which
                    awaits to be revealed at the divine time.
                  </p>
                  <p>
                    <strong>Gold Throne Pass Benefits - </strong>For each Gold
                    Throne Pass you are holding, you are granted 1 FREE mint of
                    the TOP 888 rarest tokens in the WU - 10K Assemble
                    collection along with First Priority mint for all future WU
                    collections.
                  </p>
                  <p>
                    <strong>Gold Throne Pass + OC Collection - </strong>If you
                    hold an OC collection token with a gold throne pass, it
                    grants you an additional 2 FREE mints of the top 888 rarest
                    tokens in the WU - 10K Assemble collection along with First
                    Priority mint for all future WU collections.
                  </p>
                  <p>
                    <strong>Silver Throne Pass Benefits - </strong>For each
                    Silver Throne Pass you are holding, you are granted 1 FREE
                    mint of the NEXT 3333 rarest tokens in the WU - 10K Assemble
                    collection along with Second Priority mint for all future WU
                    collections.
                  </p>
                  <p>
                    <strong>Silver Throne Pass + OC Collection - </strong>If you
                    hold an OC collection token with a silver throne pass it
                    grants you an additional 2 FREE mints of the NEXT 3333
                    rarest tokens in the WU - 10K Assemble collection along with
                    First Priority mint for all future WU collections.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-5">
              <OwlSlider
                className="pass"
                {...{
                  loop: true,
                  margin: 0,
                  animateOut: "fadeOut",
                  autoplay: true,
                  mouseDrag: false,
                  autoplayTimeout: 2500,
                  nav: false,
                  dots: false,
                  responsive: {
                    0: {
                      items: 1,
                      mouseDrag: false,
                    },
                  },
                }}
              >
                <div className="item">
                  <img
                    className="slide-img"
                    src="images/recent-item1.jpg"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    className="slide-img"
                    src="images/recent-item2.jpg"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    className="slide-img"
                    src="images/recent-item3.jpg"
                    alt=""
                  />
                </div>
              </OwlSlider>
              <img className="chair-icon" src="images/chair.png" alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* RECENT ACTIVITY */}
      {/* <section className="recent-act section-pd">
        <div className="container">
          <h2>RECENT ACTIVITY</h2>
          <OwlSlider
            className="recent-act-carousel"
            {...{
              center: true,
              items: 2,
              loop: true,
              margin: 60,
              autoplay: false,
              dots: true,
              responsive: {
                992: {
                  items: 3,
                },
                768: {
                  items: 2,
                },
                576: {
                  items: 1,
                  margin: 10,
                  stagePadding: 90,
                },
                380: {
                  items: 1,
                  margin: 10,
                  stagePadding: 0,
                },
                0: {
                  items: 1,
                  margin: 10,
                },
              },
            }}
          >
            <div className="item">
              <div className="recent-img-frame">
                <img
                  src="images/recent-item1.jpg"
                  alt=""
                  title=""
                  className="img-fluid"
                />
              </div>
              <h3>WU #3531</h3>
              <p>
                Sold for 8.39 ETH / 22,016 USD <span>56 minutes ago</span>
              </p>
            </div>
            <div className="item">
              <div className="recent-img-frame">
                <img
                  src="images/recent-item2.jpg"
                  alt=""
                  title=""
                  className="img-fluid"
                />
              </div>
              <h3>WU #3531</h3>
              <p>
                Sold for 8.39 ETH / 22,016 USD <span>56 minutes ago</span>
              </p>
            </div>
            <div className="item">
              <div className="recent-img-frame">
                <img
                  src="images/recent-item3.jpg"
                  alt=""
                  title=""
                  className="img-fluid"
                />
              </div>
              <h3>WU #3531</h3>
              <p>
                Sold for 8.39 ETH / 22,016 USD <span>56 minutes ago</span>
              </p>
            </div>
            <div className="item">
              <div className="recent-img-frame">
                <img
                  src="images/recent-item1.jpg"
                  alt=""
                  title=""
                  className="img-fluid"
                />
              </div>
              <h3>WU #3531</h3>
              <p>
                Sold for 8.39 ETH / 22,016 USD <span>56 minutes ago</span>
              </p>
            </div>
            <div className="item">
              <div className="recent-img-frame">
                <img
                  src="images/recent-item2.jpg"
                  alt=""
                  title=""
                  className="img-fluid"
                />
              </div>
              <h3>WU #3531</h3>
              <p>
                Sold for 8.39 ETH / 22,016 USD <span>56 minutes ago</span>
              </p>
            </div>
            <div className="item">
              <div className="recent-img-frame">
                <img
                  src="images/recent-item3.jpg"
                  alt=""
                  title=""
                  className="img-fluid"
                />
              </div>
              <h3>WU #3531</h3>
              <p>
                Sold for 8.39 ETH / 22,016 USD <span>56 minutes ago</span>
              </p>
            </div>
          </OwlSlider>

          <ul className="counter-info">
            <li>
              <span>{digitFormatter(stats?.total_supply)}</span> ITEMS
            </li>
            <li>
              <span>{`${digitFormatter(stats?.total_volume)} ETH`}</span> VOLUME
            </li>
            <li>
              <span>{`${digitFormatter(
                stats?.seven_day_average_price
              )} ETH`}</span>{" "}
              AV SALE, 7D
            </li>
            <li>
              <span>{stats?.num_owners}</span> OWNERS
            </li>
            <li>
              <span>{`${digitFormatter(stats?.floor_price)} ETH`}</span> FLOOR
              PRICE
            </li>
          </ul>
          <div className="text-center mt-5">
            <a
              href={"https://opensea.io/collection/women-unite-10k-assemble"}
              target={"_blank"}
              rel={"noreferrer"}
              className="btn"
            >
              View Collection
            </a>
          </div>
        </div>
      </section> */}

      <section className="tridents-bg">
        <div className="container">
          <div className="tridents-row">
            <div className="tri-left-img">
              <a
                href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/110063331335139009547571421808057778384289125434812320670302640640243544359134"
                className="btn gold"
                target={"_blank"}
                rel="noreferrer"
              >
                GET A TRIDENT NOW
              </a>
            </div>
            <div className="tri-right-content">
              <img
                src="images/tridents-logo.png"
                className="tridents-logo"
                alt=""
                title=""
              />
              <em>
                Only those who posses a WU Trident may summon “The Forgotten
                Warriors”
              </em>
              <p>
                The lore of the WU Kingdom tells of 666 mystical tridents that
                have been stashed away within the Women Unite - Treasure
                Chamber. Along with the tridents came an instruction manual,
                that reveals there are 3 different tridents, Red, Blue, and
                Gold, the gold trident being the most powerful. The tridents can
                be wielded by up to 3 of the Women Unite 10K Assemble Women in
                order to awaken the Women Unite - Forgotten Warrior.
              </p>
              <ul>
                <li>
                  <img
                    src="images/tri1.png"
                    alt=""
                    title=""
                    className="img-fluid"
                  />{" "}
                  222 Red Tridents Launch in April 2022, can awaken the
                  Forgotten Warriors every 150 Days
                </li>
                <li>
                  <img
                    src="images/tri2.png"
                    alt=""
                    title=""
                    className="img-fluid"
                  />{" "}
                  222 Blue Tridents Launch in June 2022, can awaken the
                  Forgotten Warriors every 90 Days
                </li>
                <li>
                  <img
                    src="images/tri3.png"
                    alt=""
                    title=""
                    className="img-fluid"
                  />{" "}
                  222 Gold Tridents Launch in October 2022, can awaken the
                  Forgotten Warriors every 30 Days
                </li>
              </ul>
              <p className="mt-4">
                Learn how you can{" "}
                <b>mint up to 3 Forgotten Warriors on Launch Day</b> :
                <a
                  href="https://bit.ly/WUTCDiagram"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {" "}
                  https://bit.ly/WUTCDiagram
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Gamification */}
      <section className="gamification">
        <img
          src="images/gamification-img.jpg"
          alt=""
          title=""
          className="gm-bg img-fluid"
        />
        <div className="gamification-content">
          <div className="container">
            <h3>What Is The Gamification Behind Women Unite?</h3>
            <p>How to multiply your tokens and generate rewards</p>
            <Link
              to={routesList.infographic}
              className="btn gold"
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </Link>
            <div className="screen-view desk-view">
              <img
                src="images/gamification-desktop.png"
                alt=""
                title=""
                className="img-fluid"
              />
            </div>
            <div className="screen-view mobile-view">
              <img
                src="images/gamification-mobile.png"
                alt=""
                title=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      {/* women empower */}
      <section className="women-empower">
        <div className="inside-wu">
          <h3>Empowered Women, Empower Women.</h3>
          <Link to={routesList.about} className="btn green">
            IT'S TIME TO GIVE BACK...
          </Link>
        </div>
      </section>

      {/* Testimonals */}
      {/* <section className="testimonals section-pd">
        <h2>THE WU COMMUNITY</h2>
        <OwlSlider
          className="testimonals-carousel"
          {...{
            center: true,
            stagePadding: 150,
            items: 2,
            autoplay: true,
            loop: true,
            margin: 30,
            dots: true,
            responsive: {
              1199: {
                items: 2,
              },
              600: {
                items: 1,
              },
              0: {
                items: 1,
                stagePadding: 30,
              },
            },
          }}
        >
          <div className="testm-item">
            <img src="images/wu1.jpg" alt="" title="" className="img-fluid" />
            <div className="testm-content">
              <p>
                "The best thing to happen to me in the most uncertain time of my
                life!"
              </p>
              <span className="testm-name">Leah Marville</span>
              <span className="testm-post">Attorney, Miss Barbados 2009</span>
            </div>
          </div>

          <div className="testm-item">
            <img src="images/wu2.jpg" alt="" title="" className="img-fluid" />
            <div className="testm-content">
              <p>
                "The best thing to happen to me in the most uncertain time of my
                life!"
              </p>
              <span className="testm-name">Leah Marville</span>
              <span className="testm-post">Attorney, Miss Barbados 2009</span>
            </div>
          </div>

          <div className="testm-item">
            <img src="images/wu3.jpg" alt="" title="" className="img-fluid" />
            <div className="testm-content">
              <p>
                "The best thing to happen to me in the most uncertain time of my
                life!"
              </p>
              <span className="testm-name">Leah Marville</span>
              <span className="testm-post">Attorney, Miss Barbados 2009</span>
            </div>
          </div>

          <div className="testm-item">
            <img src="images/wu4.jpg" alt="" title="" className="img-fluid" />
            <div className="testm-content">
              <p>
                "The best thing to happen to me in the most uncertain time of my
                life!"
              </p>
              <span className="testm-name">Leah Marville</span>
              <span className="testm-post">Attorney, Miss Barbados 2009</span>
            </div>
          </div>

          <div className="testm-item">
            <img src="images/wu5.jpg" alt="" title="" className="img-fluid" />
            <div className="testm-content">
              <p>
                "The best thing to happen to me in the most uncertain time of my
                life!"
              </p>
              <span className="testm-name">Leah Marville</span>
              <span className="testm-post">Attorney, Miss Barbados 2009</span>
            </div>
          </div>

          <div className="testm-item">
            <img src="images/wu6.jpg" alt="" title="" className="img-fluid" />
            <div className="testm-content">
              <p>
                "The best thing to happen to me in the most uncertain time of my
                life!"
              </p>
              <span className="testm-name">Leah Marville</span>
              <span className="testm-post">Attorney, Miss Barbados 2009</span>
            </div>
          </div>
        </OwlSlider>
      </section> */}

      {/* Team */}
      <section className="team section-pd">
        <div className="container">
          <h2>MEET THE TEAM</h2>
          <OwlSlider
            className="team-carousel"
            {...{
              loop: false,
              margin: 0,
              autoplay: false,
              mouseDrag: false,
              nav: false,
              dots: false,
              responsive: {
                0: {
                  items: 1,
                },
              },
            }}
          >
            <div className="team-item">
              <img
                src="images/team1.png"
                alt=""
                title=""
                className="img-fluid"
              />
            </div>
            <div className="team-item">
              <img
                src="images/team1.png"
                alt=""
                title=""
                className="img-fluid"
              />
            </div>
            <div className="team-item">
              <img
                src="images/team1.png"
                alt=""
                title=""
                className="img-fluid"
              />
            </div>
            <div className="team-item">
              <img
                src="images/team1.png"
                alt=""
                title=""
                className="img-fluid"
              />
            </div>
          </OwlSlider>
          <div className="text-center mt-5">
            <Link to={routesList.team} className="btn">
              View All Team
            </Link>
          </div>
        </div>
      </section>

      {/* join */}
      <section className="join-com">
        <div className="row">
          <div className="col-lg-6">
            <div className="join-lt">
              <h2>New to NFT’s?</h2>
              <p>
                We are here to lend a helping hand to all who are looking to
                gain more knowledge about the NFTs and empower themselves with
                the right tools to be able to create abundance in their lives.
              </p>
              <p>
                Our Education Hub is a great resource for understanding the
                basics of NFTs, navigating different platforms, Knowing How To
                Stay Secure, and so much more!
              </p>
              <a
                className="btn"
                data-bs-toggle="modal"
                data-bs-target="#coming-modal"
              >
                EDUCATION HUB
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="join-rt">
              <h2>Want to join our high vibe tribe?</h2>
              <p>
                We welcome all those who believe in our vision with open arms.
                Connect with us by joining our socials on Twitter, Instagram,
                and Discord to begin your journey through the WU Kingdom as part
                of the community.{" "}
              </p>
              <ul className="social-icon join">
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCbC26jObRCXZ7nEFhz5CRCw"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-youtube"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/womenunitenft/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://discord.com/invite/MG7C59rBwH"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-discord"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/WomenUniteNFT"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* join */}
      {/* <section className="womanRow">
        <SlickSlider
          {...{
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            draggable: true,
            infinite: true,
            autoplaySpeed: 0,
            speed: 6000,
            pauseOnHover: true,
            arrows: false,
            cssEase: "linear",
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
            ],
          }}
        >
          <div>
            <a href="https://www.instagram.com/womenunitenft/" target="_">
              <img
                src="images/wu01.jpg"
                alt=""
                title=""
                className="img-fluid"
              />
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/womenunitenft/" target="_">
              <img
                src="images/wu02.jpg"
                alt=""
                title=""
                className="img-fluid"
              />
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/womenunitenft/" target="_">
              <img
                src="images/wu03.jpg"
                alt=""
                title=""
                className="img-fluid"
              />
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/womenunitenft/" target="_">
              <img
                src="images/wu04.jpg"
                alt=""
                title=""
                className="img-fluid"
              />
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/womenunitenft/" target="_">
              <img
                src="images/wu03.jpg"
                alt=""
                title=""
                className="img-fluid"
              />
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/womenunitenft/" target="_">
              <img
                src="images/wu02.jpg"
                alt=""
                title=""
                className="img-fluid"
              />
            </a>
          </div>
        </SlickSlider>
        <div className="wu-carousel-home"></div>
      </section> */}

      <InstagramFeed
        access_token={process.env.REACT_APP_INSTAGRAM_FEED_APP_TOKEN}
        limit={10}
      />

      <div
        className="modal fade join-wu-modal "
        id="coming-modal"
        tabIndex="-1"
        aria-labelledby="join-modal-Label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="join-body">
              <div className="join-title">Coming Soon</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
