import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routesList, scrollToTop } from "../Common";
import { OwlSlider } from "../Common/OwlSlider";
import { SlickSlider } from "../Common/SlickSlider";
import useWalletChange from "../hooks/useWalletChange";

const About = () => {
  const { isWalletConnected, changedAddress } = useWalletChange();

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      {/* Banner */}
      <section className="innner-banner aboutbg">
        <div className="container">
          <img className="ban-girl" src="images/about-ban-girls.png" alt="" />
          <div className="ban-canvas">
            <div className="big-title">About</div>
          </div>
        </div>
      </section>

      <section className="story_wu section-pd pb-0">
          <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-6"> 
                      <div className="outervimeo"> 
                        <iframe width="560" height="100%" src="https://www.youtube.com/embed/-ajZb-fwE6Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                  </div>
                  <div className="col-md-12 col-lg-6">
                      {/* <h2 className="mb-4">The Creative Process</h2> */}
                      <div className="text-center">
                        <img src="images/story_wu.png" alt="" title="" className="img-fluid mb-4" width={400} />
                      </div>
                      <p>From the humble beginnings of the 6 Original Women Unite models to the distant future of a collection built within the Metaverse, the epic tales begin here. The story depicts the origins of how the Women Unite collections came into being; from disastrous challenges to unimaginable successes, including the development of the WU Kingdom, all shared in comic form....</p> 
                  </div>
                  
              </div> 
          </div>
      </section> 

      <section className="watchnow_banner">
        <img src="images/watchnow_banner.png" alt="" title="" className="img-fluid"  />
      </section>

    {/* <section className="creative-process section-pd">
          <div className="container">
              <div className="row">
                  <div className="col-md-12 col-lg-7">
                      <h2 className="mb-4">The Creative Process</h2>
                      <p>What makes the Women Unite collections truly unconventional is the number of man-hours (or in this case women-hours) that goes into making actual women into a virtual NFT fully equipped with unique properties. This process involves numerous hours of careful planning and preparation, tireless days of modeling and photography, and persistent weeks of hard work and dedication in order to bring this project to life. </p>
                      <p>Most NFT’s out in the market today are just randomly generated pictures created by a computer. However our mission was to bring that warm human touch into a seemingly robotic virtual world, by actually capturing each of the images in real time. This is the first of its kind series of collections that merge Photography, Modeling, and Fashion recreating this industry into something new and innovative for the virtual space. </p> 
                  </div>
                  <div className="col-md-12 col-lg-5"> 
                      <div className="outervimeo"><iframe src="https://player.vimeo.com/video/692196967?h=2fe4574ca8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" title="Vlog with new Intro and Outro 8april - 4K" frameborder="0"></iframe></div>
                      <p className="mt-3">VLOG EPISODE 1</p>
                  </div>
              </div> 
          </div>
      </section>  */}

      <section>

      </section>

      <section className="proven section-pd">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <h2 className="mb-4">The Vision</h2>
              <p>
                Our vision is to create a sanctuary where all women of the world
                can come together without any divide of race, culture, religion,
                or differences. A place where women can feel accepted, inspired
                , empowered , and united. In a world that can sometimes seem
                filled with chaos and divide, only the strength of the Divine
                feminine energies can bring forth love, light, and peace.
              </p>
              <p>
                This is the reason we decided to set out on a mission to gather
                all positive souls and lightworkers by becoming a beacon of
                beauty, oneness, and divinity. The goal of our projects is to
                provide as many opportunities for women to become abundant and
                create financial freedom in their lives and not be bogged down
                by the struggles of lack of financial resources. We have set
                forth a plan to develop not only a virtual education center for
                women to gain knowledge, but also to create a physical WU palace
                where all women can join in unity.
              </p>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="video-frame-about">
                {/* <video width="320" height="240" controls
                                poster="images/multigirl.jpg">
                                <source src="images/NFT-B-ROLL-SEQUENCE.mp4"
                                    type="video/mp4">
                                Your browser does not support the video tag.
                            </video> */}
                <div className="iframe-video-bg">
                  <img
                    src="images/video-frame-about.png"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </div>
                <img
                  src="images/wu-thumb.jpg"
                  alt=""
                  title=""
                  className="img-fluid ab-co"
                />
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 col-lg-6 order-2 order-lg-1">
              <div className="img-frame-about">
                <div className="frame">
                  <img
                    src="images/img-frame-about.png"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </div>
                <img
                  src="images/ab-co.jpg"
                  alt=""
                  title=""
                  className="img-fluid ab-co"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-6 order-1 order-lg-2">
            <h2 className="mb-4">The Mission</h2>
              <p>
                Women Unite is about the rise of the Divine feminine energies.
                We are going into that era right now. It's about how it mixes
                the active and powerful energies of the Divine feminine. That is
                why you see a lot of rise of new female women entrepreneurs, and
                how that is going to shift the whole planet in terms of
                abundance. This project is really talking about how to excel in
                2022, when it comes to your freedom, your abundance, and how you
                can move with the energies of 2022, after the great reset, and
                move with those energies and find opportunities to be able to
                pay that lifestyle freedom or financial freedom or just freedom
                for you to be able to manifest a life where you get to do what
                you want, when you want and with whom ever you want.{" "}
              </p>
              <p>
                We're encouraging female leaders and female entrepreneurs. This
                is the time for the feminine power. We've just finished the
                completion of 2020, which was the reset, which was a lot of
                destruction and a lot of chaos happening around the world. Now
                it's time for creation; and what we know about the feminine is
                that they are creation. They are capable of creating and bringing
                new life to the world. Feminine create human beings. So these
                are very big manifestation powers. But not to forget that Divine
                feminine and Divine masculine energies are within every single
                one of us. So this is a very crucial time in 2022 to take charge
                of your life, your freedom, your financial freedom, your
                independency and to manifest miracles. And those who know how to
                embrace the tools and strategies in 2022 are the people who will
                see all of those rewards reaping in 2023, 2024, 2025. So much so
                that it's almost like a quantum leap.
              </p>
              <p>
                We want to encourage you all, whether you are female leaders
                wanting to rise up, wanting to take charge of your life; or
                whether you are the male crowd who wants to understand how to
                tap into this energy. This is the time for that.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="the-mission">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-12 col-lg-6 align-self-end order-2
                        order-lg-1"
            >
              <img
                src="images/wu-about.png"
                alt=""
                title=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-12 col-lg-6 order-1 order-lg-2">
            <h2 className="">Charity</h2>
              <p>
                The Women Unite project believes that with great abundance comes
                great ability to help others. This is why we've dedicated 10% of
                proceeds to assist with women in need through charity. The Women
                Unite models and the team come from a place of compassion,
                gratitude, and love and believe that it's not just about the
                monetary materials that make a difference but also the act of
                contribution. The models themselves also participate on site to
                spread the message of love and positivity. With the support of
                all our WU kingdom members we are able to create a bigger impact
                on women all across the globe.{" "}
              </p> 
            </div>
          </div>
        </div>
      </section>

      <section className="makingwu text-center section-pd">
        <div className="container">
          <h2>THE MAKING OF WU</h2>
          <p>
            12 Days - 108 Hours Photoshoot - 7 Stylists - 3 Make Up Artists - 48
            Models - 22 Nationalities and a whole lot of love.{" "}
          </p>

          <SlickSlider
            {...{
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true, 
              infinite: true, 
              speed: 300, 
              arrows: true,  
            }}
          >
            <div className="">
              <iframe
                src="https://player.vimeo.com/video/697676819?h=dd64b09493&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;title=0&amp;byline=0&amp;portrait=0"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="test"
              ></iframe>
              <p>BEHIND THE SCENES EPISODE 1</p>
            </div>
            <div className="">
              <iframe
                src="https://player.vimeo.com/video/697677880?h=25eda2d96e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;title=0&amp;byline=0&amp;portrait=0"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="test"
              ></iframe>
              <p>BEHIND THE SCENES EPISODE 2</p>
            </div>
            <div className="">
              <iframe
                src="https://player.vimeo.com/video/697680048?h=a010f39dc7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;title=0&amp;byline=0&amp;portrait=0"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="test"
              ></iframe>
              <p>BEHIND THE SCENES EPISODE 3</p>
            </div>
          </SlickSlider>
          <div className="mt-4 text-center">
            <a
              href="https://opensea.io/collection/women-unite-10k-assemble"
              target="_blank"
              rel="noreferrer"
              className="btn"
            >
              VIEW COLLECTION
            </a>
          </div>
        </div>
      </section>

      {/* Team */}
      {/* <section className="creative-process section-pd">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-7">
              <h2 className="mb-4">The Creative Process</h2>
              <p>
                What makes the Women Unite collections truly unconventional is
                the number of man-hours (or in this case women-hours) that goes
                into making actual women into a virtual NFT fully equipped with
                unique properties. This process involves numerous hours of
                careful planning and preparation, tireless days of modeling and
                photography, and persistent weeks of hard work and dedication in
                order to bring this project to life.{" "}
              </p>
              <p>
                Most NFT's out in the market today are just randomly generated
                pictures created by a computer. However our mission was to bring
                that warm human touch into a seemingly robotic virtual world, by
                actually capturing each of the images in real time. This is the
                first of its kind series of collections that merge Photography,
                Modeling, and Fashion recreating this industry into something
                new and innovative for the virtual space.{" "}
              </p>
            </div>
            <div className="col-md-12 col-lg-5">
              <div className="outervimeo">
                <iframe
                  src="https://player.vimeo.com/video/692196967?h=2fe4574ca8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  title="Vlog with new Intro and Outro 8april - 4K"
                ></iframe>
              </div>
              <p className="mt-3">VLOG EPISODE 1</p>
            </div>
          </div>
        </div>
      </section> */}

      {/* Team */}
      <section className="team section-pd">
        <div className="container">
          <h2>MEET THE TEAM</h2>
          <OwlSlider
            className="team-carousel"
            {...{
              loop: false,
              margin: 0,
              autoplay: false,
              mouseDrag: false,
              nav: false,
              dots: false,
              responsive: {
                0: {
                  items: 1,
                },
              },
            }}
          >
            <div className="team-item">
              <img
                src="images/team1.png"
                alt=""
                title=""
                className="img-fluid"
              />
            </div>
            <div className="team-item">
              <img
                src="images/team1.png"
                alt=""
                title=""
                className="img-fluid"
              />
            </div>
            <div className="team-item">
              <img
                src="images/team1.png"
                alt=""
                title=""
                className="img-fluid"
              />
            </div>
            <div className="team-item">
              <img
                src="images/team1.png"
                alt=""
                title=""
                className="img-fluid"
              />
            </div>
          </OwlSlider>
          <div className="text-center mt-5">
            <Link to={routesList.team} className="btn">
              View All Team
            </Link>
          </div>
        </div>
      </section>

      {/* collaborate */}
      <section className="collaborate section-pd">
        <div className="container">
          <div className="text-center">
            <img src="images/multigirl.jpg" alt="" />
          </div>
          <div className="content-bottom">
            <h2>Want to collaborate with us?</h2>
            <p>
              WU collaborates with and supports emerging projects from around
              the globe. If you're an artist, nonprofit or brand looking to
              collaborate, reach out to us at{" "}
              <strong>womenunitenft@iamcreator.com</strong>{" "}
            </p>
            <a
              href="images/Women_Unite_Info_Pack.pdf"
              target="_blank"
              className="btn"
            >
              DOWNLOAD WU INFO PACK
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
