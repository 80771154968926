import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import {
  checkExist,
  loaderType,
  metamaskErrorMessages,
  notificationPosition,
  notificationSetting,
  notificationType,
  saleType,
  showNotification,
  StartLoader,
  StopLoader,
} from "../Common";
import { GlobalInput } from "../Global/GlobalInput";
import useWalletChange from "../hooks/useWalletChange";
import {
  approve,
  changePrivateSaleStatus,
  changePublicSaleStatus,
  changeRevealStatus,
  connectWallet,
  pause,
  privateMint,
  removeWhitelistUser,
  safeTransferFrom,
  safeTransferFromBytes,
  setApprovalForAll,
  setBaseURI,
  setmaxMintTokens,
  setNotRevealedURI,
  setPrice,
  setSupplyLimit,
  setThronePassSale,
  standardMint,
  throneMint,
  totalValues,
  transferFrom,
  transferOwnership,
  unPause,
  whiteListUsers,
  withdraw,
} from "../Scripts/interact";
import { MintButton } from "../SubSection";

export const WriteFunctions = React.forwardRef(
  (
    {
      paused,
      maxGenCount,
      maxLimitMint,
      price,
      privateSale,
      publicSale,
      revealed,
      thronePassSale,
      ownerAddress,
      onUpdatePrivateSaleStatus,
      onUpdatePublicSaleStatus,
      onUpdateRevealStatus,
      onUpdateThronePassSaleStatus,
      onUpdateBaseURI,
      onUpdateNotRevealedURI,
      onUpdatePriceValue,
      onUpdateSupplyLimit,
      onUpdateMaxMintLimit,
      onTransferOwnerShip,
      onPause,
      onUnPause,
    },
    ref
  ) => {
    const { isWalletConnected, changedAddress } = useWalletChange();
    const [form, setForm] = useState({});
    const [quantity, setQuantity] = useState({
      standardMint: 0,
      privateMint: 0,
      publicMint: 0,
      throneMint: 0,
    });
    const [maxPerMint, setMaxPerMint] = useState({
      standardMint: 0,
      privateMint: 0,
      publicMint: 0,
      throneMint: 0,
    });
    const [totalAmount, setTotalAmount] = useState({
      standardMint: 0,
      privateMint: 0,
      publicMint: 0,
      throneMint: 0,
    });
    const [maxValues, setMaxValues] = useState({
      maxMints: 0,
      totalRemaining: 0,
      price: null,
    });

    //Setter Functions
    const connectWalletPressed = async () => {
      const response = await connectWallet();

      if (response.status) {
        console.log("Wallet Connected");
      } else {
        showNotification(
          notificationType.DANGER,
          response.message,
          notificationSetting
        );
      }
    };

    const checkWalletConnected = () => {
      if (!isWalletConnected) {
        console.log("Entered");
        showNotification(
          notificationType.DANGER,
          `Kindly Connect Wallet First by Clicking "Connect to Wallet" button to perform this action.`,
          notificationSetting
        );
        return false;
      }
      return true;
    };

    const handleInputChange = (event, data) => {
      setForm({ ...form, [data.name]: data.value });
    };

    const setTotalValues = (response, mintingType) => {
      // console.log("Sale Type: ", mintSaleType);
      if (response !== undefined) {
        let key = "standardMint";
        if (mintingType === saleType.PRIVATE_SALE) {
          key = "privateMint";
          response.maxPerMint = process.env.REACT_APP_MAX_PER_MINT;
        } else if (mintingType === saleType.THRONE_SALE) {
          key = "throneMint";
          response.maxPerMint = process.env.REACT_APP_MAX_PER_MINT;
        }
        setMaxPerMint(() => {
          return { ...maxPerMint, [key]: response?.maxPerMint };
        });
        setMaxValues(() => {
          return {
            maxMints: response?.maxMints,
            totalRemaining: response?.totalRemaining,
            price: response?.price,
          };
        });
      } else {
        showNotification(
          notificationType.DANGER,
          metamaskErrorMessages.browserNotSupported,
          notificationSetting
        );
        let key = "standardMint";
        if (mintingType === saleType.PRIVATE_SALE) {
          key = "privateMint";
        } else if (mintingType === saleType.THRONE_SALE) {
          key = "throneMint";
        }
        setMaxPerMint(() => {
          return { ...maxPerMint, [key]: 0 };
        });
        setMaxValues(() => {
          return {
            maxMints: 100,
            totalRemaining: 0,
            price: null,
          };
        });
      }
    };

    const setTotalAmountValues = (quantity, mintingType) => {
      let price;
      let key = "";
      let amount = "";
      if (mintingType === saleType.PRIVATE_SALE) {
        price = 0;
        key = "privateMint";
      } else if (mintingType === saleType.THRONE_SALE) {
        price = 0;
        key = "throneMint";
      } else {
        price = maxValues.price;
        key = "standardMint";
      }
      amount = (parseFloat(quantity) * parseFloat(price)).toFixed(3);
      setTotalAmount({ ...totalAmount, [key]: amount });
    };

    //Submit Handlers
    const approveToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await approve(
        form?.approveAddress,
        form?.approveTokenId
      );
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Approved Successful",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        setForm((form) => {
          return { ...form, approveAddress: "", approveTokenId: "" };
        });
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const togglePrivateSaleToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await changePrivateSaleStatus(
        !privateSale
      );
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Private Sale Status Updated Successful",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        onUpdatePrivateSaleStatus();
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const togglePublicSaleToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await changePublicSaleStatus(
        !publicSale
      );
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Public Sale Status Updated Successful",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        onUpdatePublicSaleStatus();
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const handlePrivateMintingToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await privateMint(
        form?.numberOfTokensPrivateMint,
        0 //form?.payableAmountPrivateMint
      );
      if (status) {
        StopLoader(loaderType.MINT);
        let response = await totalValues();
        setTotalValues(response, saleType.PRIVATE_SALE);
        setQuantity({ ...quantity, privateMint: 1 });
        setTotalAmountValues(1, saleType.PRIVATE_SALE);
        showNotification(notificationType.SUCCESS, message, {
          title: "Private Minting Successful",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        setForm((form) => {
          return {
            ...form,
            numberOfTokensPrivateMint: "",
            payableAmountPrivateMint: "",
          };
        });
        // setQuantity((quantity) => {
        //   return { ...quantity, privateMint: "", approveTokenId: "" };
        // });
        // setTotalAmount((totalAmount) => {
        //   return { ...totalAmount, privateMint: "", approveTokenId: "" };
        // });
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const handleThroneMintingToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await throneMint(
        form?.numberOfTokensThroneMint,
        0 //form?.payableAmountThroneMint
      );
      if (status) {
        StopLoader(loaderType.MINT);
        let response = await totalValues();
        setTotalValues(response, saleType.THRONE_SALE);
        setQuantity({ ...quantity, throneMint: 1 });
        setTotalAmountValues(1, saleType.THRONE_SALE);
        showNotification(notificationType.SUCCESS, message, {
          title: "Throne Minting Successful",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        setForm((form) => {
          return {
            ...form,
            numberOfTokensThroneMint: "",
            payableAmountThroneMint: "",
          };
        });
        // setQuantity((quantity) => {
        //   return { ...quantity, throneMint: "", approveTokenId: "" };
        // });
        // setTotalAmount((totalAmount) => {
        //   return { ...totalAmount, throneMint: "", approveTokenId: "" };
        // });
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const handleStandardMintingToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await standardMint(
        form?.numberOfTokensMint,
        form?.payableAmountMint
      );
      if (status) {
        StopLoader(loaderType.MINT);
        let response = await totalValues();
        setTotalValues(response, saleType.STANDARD);
        setQuantity({ ...quantity, standardMint: 1 });
        setTotalAmountValues(1, saleType.STANDARD);
        showNotification(notificationType.SUCCESS, message, {
          title: "Standard Minting Successful",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        setForm((form) => {
          return { ...form, numberOfTokensMint: "", payableAmountMint: "" };
        });
        // setQuantity((quantity) => {
        //   return { ...quantity, standardMint: "", approveTokenId: "" };
        // });
        // setTotalAmount((totalAmount) => {
        //   return { ...totalAmount, standardMint: "", approveTokenId: "" };
        // });
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const toggleRevealStatusToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await changeRevealStatus(!revealed);
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Reveal Status Updated Successful",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        onUpdateRevealStatus();
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const handleRemoveWhitelistUserToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await removeWhitelistUser(
        form?.removeWhitelistUserInput
      );
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Remove Whitelisted Updated Successful",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        setForm((form) => {
          return { ...form, removeWhitelistUserInput: "" };
        });
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    // const handleSafeTransferToContract = async (event) => {
    //   event.preventDefault();
    // if (!checkWalletConnected()) return;
    //   StartLoader(loaderType.MINT);
    //   const { status, message, hash } = await safeTransferFrom(
    //     form?.safeTransferFromAddress,
    //     form?.safeTransferToAddress,
    //     form?.safeTransferTokenId
    //   );
    //   if (status) {
    //     StopLoader(loaderType.MINT);
    //     showNotification(notificationType.SUCCESS, message, {
    //       title: "Safely Transferred",
    //       duration: 6000,
    //       notificationPosition: notificationPosition.BOTTOM_RIGHT,
    //     });
    //     setForm((form) => {
    //       return {
    //         ...form,
    //         safeTransferFromAddress: "",
    //         safeTransferToAddress: "",
    //         safeTransferTokenId: "",
    //       };
    //     });
    //   } else {
    //     StopLoader(loaderType.MINT);
    //     showNotification(notificationType.DANGER, message, notificationSetting);
    //   }
    // };

    // const handleSafeTransferBytesToContract = async (event) => {
    //   event.preventDefault();
    // if (!checkWalletConnected()) return;
    //   StartLoader(loaderType.MINT);
    //   const { status, message, hash } = await safeTransferFromBytes(
    //     form?.safeTransferBytesFromAddress,
    //     form?.safeTransferBytesToAddress,
    //     form?.safeTransferBytesTokenId,
    //     form?.safeTransferBytesDataBytes
    //   );
    //   if (status) {
    //     StopLoader(loaderType.MINT);
    //     showNotification(notificationType.SUCCESS, message, {
    //       title: "Safely Transferred",
    //       duration: 6000,
    //       notificationPosition: notificationPosition.BOTTOM_RIGHT,
    //     });
    //     setForm((form) => {
    //       return {
    //         ...form,
    //         safeTransferBytesFromAddress: "",
    //         safeTransferBytesToAddress: "",
    //         safeTransferBytesTokenId: "",
    //         safeTransferBytesDataBytes: "",
    //       };
    //     });
    //   } else {
    //     StopLoader(loaderType.MINT);
    //     showNotification(notificationType.DANGER, message, notificationSetting);
    //   }
    // };

    const handleApprovedForAllToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await setApprovalForAll(
        form?.approvedForAllOperatorAddress,
        form?.approvedForAllApprovalStatus
      );
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Approved",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        setForm((form) => {
          return {
            ...form,
            approvedForAllOperatorAddress: "",
            approvedForAllApprovalStatus: "",
          };
        });
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const handleSetBaseURIToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await setBaseURI(form?.baseUriInput);
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Base URI has been Set",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        onUpdateBaseURI();
        setForm((form) => {
          return {
            ...form,
            baseUriInput: "",
          };
        });
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const handleNotRevealedURIToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await setNotRevealedURI(
        form?.notRevealedUriInput
      );
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Base URI has been Set",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        onUpdateNotRevealedURI();
        setForm((form) => {
          return {
            ...form,
            notRevealedUriInput: "",
          };
        });
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const handleSetPriceToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await setPrice(form?.priceInput);
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Price has been Set",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        onUpdatePriceValue();
        setForm((form) => {
          return {
            ...form,
            priceInput: "",
          };
        });
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const handleSetSupplyLimitToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await setSupplyLimit(
        form?.supplyLimitInput
      );
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Supply Limit has been Set",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        onUpdateSupplyLimit();
        setForm((form) => {
          return {
            ...form,
            supplyLimitInput: "",
          };
        });
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const handleSetThronePassSaleToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await setThronePassSale(
        form?.thronePassSaleInput
      );
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Throne Pass Sale Updated Successful",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        onUpdateThronePassSaleStatus();
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const handleSetMaxMintTokenInputToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await setmaxMintTokens(
        form?.maxMintTokenInput
      );
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Max Mint Tokens has been Set",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        onUpdateMaxMintLimit();
        setForm((form) => {
          return {
            ...form,
            maxMintTokenInput: "",
          };
        });
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const handleTransferToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await transferFrom(
        form?.transferFromAddress,
        form?.transferToAddress,
        form?.transferTokenId
      );
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Transferred",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        setForm((form) => {
          return {
            ...form,
            transferFromAddress: "",
            transferToAddress: "",
            transferTokenId: "",
          };
        });
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const handleTransferOwnershipToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await transferOwnership(
        form?.transferOwnershipInput
      );
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Ownership Transferred",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        onTransferOwnerShip();
        setForm((form) => {
          return {
            ...form,
            transferOwnershipInput: "",
          };
        });
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const handleWhitelistUsers = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await whiteListUsers(
        JSON.parse(form?.whiteListUserAddressInput)
      );
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Whitelisting Successful",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const handleWithdrawToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      // const { status, message, hash } = await withdraw(form?.withdrawInput);
      const { status, message, hash } = await withdraw();
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Withdrawn Successful",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const togglePauseToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await pause();
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Paused Successfully",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        onPause();
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    const toggleUnpauseToContract = async (event) => {
      event.preventDefault();
      if (!checkWalletConnected()) return;
      StartLoader(loaderType.MINT);
      const { status, message, hash } = await unPause();
      if (status) {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.SUCCESS, message, {
          title: "Unpaused Successfully",
          duration: 6000,
          notificationPosition: notificationPosition.BOTTOM_RIGHT,
        });
        onUnPause();
      } else {
        StopLoader(loaderType.MINT);
        showNotification(notificationType.DANGER, message, notificationSetting);
      }
    };

    return (
      <>
        <div className="connectWalletButton">
          {!isWalletConnected ? (
            <button className="btn clickable" onClick={connectWalletPressed}>
              Connect to Wallet
            </button>
          ) : (
            changedAddress && (
              <button
                className="btn clickable"
                disabled
              >{`Connected: ${changedAddress.substring(
                0,
                4
              )}...${changedAddress.substring(
                parseInt(changedAddress.length) - 4,
                changedAddress.length
              )}`}</button>
            )
          )}
        </div>
        <hr />
        <Accordion defaultActiveKey="setPrice">
          <Accordion.Item eventKey="setPrice">
            <Accordion.Header>1. setPrice</Accordion.Header>
            <Accordion.Body>
              <h6 className="mb-3">{`Current Price: ${price}(wei)`}</h6>

              <GlobalInput
                label={"_newPrice (uint256)"}
                placeholder={"_newPrice (uint256)"}
                id={"priceInput"}
                name={"priceInput"}
                value={form?.priceInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <pre className="text-secondary">Enter value in ETH(ethers)</pre>
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleSetPriceToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="setBaseURI">
            <Accordion.Header>2. setBaseURI</Accordion.Header>
            <Accordion.Body>
              <GlobalInput
                label={"_newBaseURI (string)"}
                placeholder={"_newBaseURI (string)"}
                id={"baseUriInput"}
                name={"baseUriInput"}
                value={form?.baseUriInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleSetBaseURIToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="setThronePassSale">
            <Accordion.Header>3. setThronePassSale</Accordion.Header>
            <Accordion.Body>
              <h6 className="mb-3">{`Current Status: ${thronePassSale}`}</h6>

              <GlobalInput
                type={"number"}
                label={"_type (uint256)"}
                placeholder={"_type (uint256)"}
                id={"thronePassSaleInput"}
                name={"thronePassSaleInput"}
                min={0}
                max={2}
                value={form?.thronePassSaleInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <pre className="text-secondary">
                e.g.: 0:None/SaleOff 1:Gold 2:Silver
              </pre>
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleSetThronePassSaleToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="togglePrivateSale">
            <Accordion.Header>4. togglePrivateSale</Accordion.Header>
            <Accordion.Body>
              {checkExist(privateSale) && (
                <h6 className="mb-3">{`Current Status: ${
                  privateSale ? "true" : "false"
                }`}</h6>
              )}

              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={togglePrivateSaleToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="togglePublicSale">
            <Accordion.Header>5. togglePublicSale</Accordion.Header>
            <Accordion.Body>
              {checkExist(publicSale) && (
                <h6 className="mb-3">{`Current Status: ${
                  publicSale ? "true" : "false"
                }`}</h6>
              )}

              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={togglePublicSaleToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="privateMint">
            <Accordion.Header>6. privateMint</Accordion.Header>
            <Accordion.Body>
              <div className="mb-4">
                <GlobalInput
                  label={"payableAmount (ether)"}
                  placeholder={"payableAmount (ether)"}
                  id={"payableAmountPrivateMint"}
                  name={"payableAmountPrivateMint"}
                  value={form?.payableAmountPrivateMint}
                  onChange={(event, data) => handleInputChange(event, data)}
                />
              </div>

              <GlobalInput
                label={"numberOfTokens (uint256)"}
                placeholder={"numberOfTokens (uint256)"}
                id={"numberOfTokensPrivateMint"}
                name={"numberOfTokensPrivateMint"}
                value={form?.numberOfTokensPrivateMint}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handlePrivateMintingToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item> */}
          {/* <Accordion.Item eventKey="throneMint">
            <Accordion.Header>7. throneMint</Accordion.Header>
            <Accordion.Body>
              <div className="mb-4">
                <GlobalInput
                  label={"payableAmount (ether)"}
                  placeholder={"payableAmount (ether)"}
                  id={"payableAmountThroneMint"}
                  name={"payableAmountThroneMint"}
                  value={form?.payableAmountThroneMint}
                  onChange={(event, data) => handleInputChange(event, data)}
                />
              </div>

              <GlobalInput
                label={"numberOfTokens (uint256)"}
                placeholder={"numberOfTokens (uint256)"}
                id={"numberOfTokensThroneMint"}
                name={"numberOfTokensThroneMint"}
                value={form?.numberOfTokensThroneMint}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleThroneMintingToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item> */}
          <Accordion.Item eventKey="standardMint">
            <Accordion.Header>6. mint</Accordion.Header>
            <Accordion.Body>
              <div className="mb-4">
                <GlobalInput
                  label={"payableAmount (ether)"}
                  placeholder={"payableAmount (ether)"}
                  id={"payableAmountMint"}
                  name={"payableAmountMint"}
                  value={form?.payableAmountMint}
                  onChange={(event, data) => handleInputChange(event, data)}
                />
              </div>

              <GlobalInput
                label={"numberOfTokens (uint256)"}
                placeholder={"numberOfTokens (uint256)"}
                id={"numberOfTokensMint"}
                name={"numberOfTokensMint"}
                value={form?.numberOfTokensMint}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleStandardMintingToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="setmaxMintTokens">
            <Accordion.Header>7. setMaxMintTokens</Accordion.Header>
            <Accordion.Body>
              {checkExist(maxLimitMint) && (
                <h6 className="mb-3">{`Current Limit: ${maxLimitMint}`}</h6>
              )}
              <GlobalInput
                label={"_newmaxMintTokens (uint256)"}
                placeholder={"_newmaxMintTokens (uint256)"}
                id={"maxMintTokenInput"}
                name={"maxMintTokenInput"}
                value={form?.maxMintTokenInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleSetMaxMintTokenInputToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="setSupplyLimit">
            <Accordion.Header>8. setSupplyLimit</Accordion.Header>
            <Accordion.Body>
              {checkExist(maxGenCount) && (
                <h6 className="mb-3">{`Current Limit: ${maxGenCount}`}</h6>
              )}

              <GlobalInput
                label={"_newLimit (uint256)"}
                placeholder={"_newLimit (uint256)"}
                id={"supplyLimitInput"}
                name={"supplyLimitInput"}
                value={form?.supplyLimitInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleSetSupplyLimitToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="pause">
            <Accordion.Header>9. pause</Accordion.Header>
            <Accordion.Body>
              {checkExist(paused) && (
                <h6 className="mb-3">{`Paused: ${
                  paused ? "true" : "false"
                }`}</h6>
              )}

              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={togglePauseToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="unpause">
            <Accordion.Header>10. unpause</Accordion.Header>
            <Accordion.Body>
              {checkExist(paused) && (
                <h6 className="mb-3">{`Paused: ${
                  paused ? "true" : "false"
                }`}</h6>
              )}

              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={toggleUnpauseToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="transferFrom">
            <Accordion.Header>11. transferFrom</Accordion.Header>
            <Accordion.Body>
              <h6 className="mb-3">{`See {IERC721-transferFrom}.`}</h6>

              <div className="mb-4">
                <GlobalInput
                  label={"from (address)"}
                  placeholder={"from (address)"}
                  id={"transferFromAddress"}
                  name={"transferFromAddress"}
                  value={form?.transferFromAddress}
                  onChange={(event, data) => handleInputChange(event, data)}
                />
              </div>

              <div className="mb-4">
                <GlobalInput
                  label={"to (address)"}
                  placeholder={"to (address)"}
                  id={"transferToAddress"}
                  name={"transferToAddress"}
                  value={form?.transferToAddress}
                  onChange={(event, data) => handleInputChange(event, data)}
                />
              </div>

              <GlobalInput
                label={"tokenId (uint256)"}
                placeholder={"tokenId (uint256)"}
                id={"transferTokenId"}
                name={"transferTokenId"}
                value={form?.transferTokenId}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleTransferToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="transferOwnership">
            <Accordion.Header>12. transferOwnership</Accordion.Header>
            <Accordion.Body>
              <h6 className="mb-3">{`Transfers ownership of the contract to a new account ('newOwner'). Can only be called by the current owner.`}</h6>

              <GlobalInput
                label={"newOwner (address)"}
                placeholder={"newOwner (address)"}
                id={"transferOwnershipInput"}
                name={"transferOwnershipInput"}
                value={form?.transferOwnershipInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleTransferOwnershipToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="whitelistUsers">
            <Accordion.Header>13. whitelistUsers</Accordion.Header>
            <Accordion.Body>
              <GlobalInput
                label={"_user (address[])"}
                placeholder={"_user (address[])"}
                id={"whiteListUserAddressInput"}
                name={"whiteListUserAddressInput"}
                value={form?.whiteListUserAddressInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <pre className="text-secondary">
                e.g.:
                ["0x12a....bc123","0x12a....bc123","0x12a....bc123","0x12a....bc123"]
              </pre>
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleWhitelistUsers}
              >
                Query!
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="removeWhiteListUser">
            <Accordion.Header>14. removeWhiteListUser</Accordion.Header>
            <Accordion.Body>
              <GlobalInput
                label={"_use(address)"}
                placeholder={"_use(address)"}
                id={"removeWhitelistUserInput"}
                name={"removeWhitelistUserInput"}
                value={form?.removeWhitelistUserInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleRemoveWhitelistUserToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="approve">
            <Accordion.Header>15. approve</Accordion.Header>
            <Accordion.Body>
              <h6 className="mb-3">{`See {IERC721-approve}.`}</h6>

              <div className="mb-4">
                <GlobalInput
                  label={"to (address)"}
                  placeholder={"to (address)"}
                  id={"approveAddress"}
                  name={"approveAddress"}
                  value={form?.approveAddress}
                  onChange={(event, data) => handleInputChange(event, data)}
                />
              </div>

              <GlobalInput
                label={"_tokenId"}
                placeholder={"_tokenId"}
                id={"approveTokenId"}
                name={"approveTokenId"}
                value={form?.approveTokenId}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={approveToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="setApproveForAll">
            <Accordion.Header>16. setApproveForAll</Accordion.Header>
            <Accordion.Body>
              <h6 className="mb-3">{`See {IERC721-setApprovalForAll}.`}</h6>

              <div className="mb-4">
                <GlobalInput
                  label={"operator (address)"}
                  placeholder={"operator (address)"}
                  id={"approvedForAllOperatorAddress"}
                  name={"approvedForAllOperatorAddress"}
                  value={form?.approvedForAllOperatorAddress}
                  onChange={(event, data) => handleInputChange(event, data)}
                />
              </div>

              <GlobalInput
                label={"approved (bool)"}
                placeholder={"approved (bool)"}
                id={"approvedForAllApprovalStatus"}
                name={"approvedForAllApprovalStatus"}
                value={form?.approvedForAllApprovalStatus}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <pre className="text-secondary">true/false</pre>
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleApprovedForAllToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="reveal">
            <Accordion.Header>17. reveal</Accordion.Header>
            <Accordion.Body>
              {checkExist(revealed) && (
                <h6 className="mb-3">{`Current Status: ${
                  revealed ? "true" : "false"
                }`}</h6>
              )}

              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={toggleRevealStatusToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="setNotRevealedURI">
            <Accordion.Header>18. setNotRevealedURI</Accordion.Header>
            <Accordion.Body>
              <GlobalInput
                label={"_notRevealedURI (string)"}
                placeholder={"_notRevealedURI (string)"}
                id={"notRevealedUriInput"}
                name={"notRevealedUriInput"}
                value={form?.notRevealedUriInput}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleNotRevealedURIToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="withdraw">
            <Accordion.Header>19. withdraw</Accordion.Header>
            <Accordion.Body>
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleWithdrawToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="safeTransferFrom">
            <Accordion.Header>safeTransferFrom</Accordion.Header>
            <Accordion.Body>
              <h6 className="mb-3">{`See {IERC721-safeTransferFrom}.`}</h6>
              
              <GlobalInput
                label={"from (address)"}
                placeholder={"from (address)"}
                id={"safeTransferFromAddress"}
                name={"safeTransferFromAddress"}
                value={form?.safeTransferFromAddress}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              
              <GlobalInput
                label={"to (address)"}
                placeholder={"to (address)"}
                id={"safeTransferToAddress"}
                name={"safeTransferToAddress"}
                value={form?.safeTransferToAddress}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              
              <GlobalInput
                label={"tokenId (uint256)"}
                placeholder={"tokenId (uint256)"}
                id={"safeTransferTokenId"}
                name={"safeTransferTokenId"}
                value={form?.safeTransferTokenId}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleSafeTransferToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item> 
          <Accordion.Item eventKey="safeTransferFromBytes">
            <Accordion.Header>safeTransferFrom</Accordion.Header>
            <Accordion.Body>
              <h6 className="mb-3">{`See {IERC721-safeTransferFrom}.`}</h6>
              
              <GlobalInput
                label={"from (address)"}
                placeholder={"from (address)"}
                id={"safeTransferBytesFromAddress"}
                name={"safeTransferBytesFromAddress"}
                value={form?.safeTransferBytesFromAddress}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              
              <GlobalInput
                label={"to (address)"}
                placeholder={"to (address)"}
                id={"safeTransferBytesToAddress"}
                name={"safeTransferBytesToAddress"}
                value={form?.safeTransferBytesToAddress}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              
              <GlobalInput
                label={"tokenId (uint256)"}
                placeholder={"tokenId (uint256)"}
                id={"safeTransferBytesTokenId"}
                name={"safeTransferBytesTokenId"}
                value={form?.safeTransferBytesTokenId}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              
              <GlobalInput
                label={"_data (bytes)"}
                placeholder={"_data (bytes)"}
                id={"safeTransferBytesDataBytes"}
                name={"safeTransferBytesDataBytes"}
                value={form?.safeTransferBytesDataBytes}
                onChange={(event, data) => handleInputChange(event, data)}
              />
              <MintButton
                type={"button"}
                className={"btn btn-di mt-3 wallet-connect"}
                onClick={handleSafeTransferBytesToContract}
              >
                Write
              </MintButton>
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>
      </>
    );
  }
);
