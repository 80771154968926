/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  loaderType,
  manageCounterProcessing,
  metamaskErrorMessages,
  mintPhase,
  notificationPosition,
  notificationSetting,
  notificationType,
  routesList,
  saleType,
  sendStatusForMint,
  showNotification,
  StartLoader,
  StopLoader,
  throneType,
} from "../Common";
import { OwlSlider } from "../Common/OwlSlider";
import useWalletChange from "../hooks/useWalletChange";
import {
  getBalanceOf,
  getMaxGenCount,
  getPausedStatus,
  getPrice,
  getPrivateSaleStatus,
  getPublicSaleStatus,
  getThronePassSale,
  getTotalSupply,
  maxPerMintTokens,
  mintNFT,
  totalValues,
} from "../Scripts/interact";
import { CommonService } from "../Services/CommonService";
import { MintButton } from "../SubSection";

const Mint = () => {
  const {
    isWalletConnected,
    changedAddress,
    changedBalance,
    changedChainId,
    changedMessage,
  } = useWalletChange();
  const [price, setPrice] = useState();
  const [maxGenCount, setMaxGenCount] = useState("");
  const [maxPerMint, setMaxPerMint] = useState("");
  const [paused, setPaused] = useState(false);
  const [privateSale, setPrivateSale] = useState(false);
  const [publicSale, setPublicSale] = useState(false);
  const [thronePassSale, setThronePassSale] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [mintOnHold, setMintOnHold] = useState(false);
  const [holdMessage, setHoldMessage] = useState("");
  const [phase, setPhase] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);
  const [maxValues, setMaxValues] = useState({
    maxMints: null,
    totalEthers: null,
    price: null,
  });
  const unmount = useRef();
  const quantityRef = useRef();

  useEffect(async () => {
    if (changedAddress !== "" && changedAddress !== null) {
      callContractFunctions();
      getTokenType();
    }
  }, [changedAddress]);

  useEffect(() => {
    // if (phase === mintPhase.PUBLIC) {
    if (parseInt(totalSupply) >= parseInt(maxGenCount)) {
      setMintOnHold((mintOnHold) => {
        return true;
      });
      setHoldMessage((holdMessage) => {
        return "No More NFTs for Sale";
      });
    }
    // }
  }, [totalSupply, maxGenCount]);

  useEffect(() => {
    // console.log("Price: ", quantity, price, phase);
    if (price) {
      // getMaxPerMintTokensFromContract();
      setTotalAmountValues(quantity);
    }
  }, [quantity, price, phase]);

  function callContractFunctions() {
    getPriceFromContract();
    getMaxPerMintTokensFromContract();
    getMaxGenCountFromContract();
    getPausedFromContract();
    getPrivateSaleFromContract();
    getPublicSaleFromContract();
    getThronePassSaleFromContract();
    getTotalSupplyFromContract();
  }
  // Contract Functions
  const getPriceFromContract = async () => {
    setWaiting((waiting) => {
      return true;
    });
    const response = await getPrice();
    if (response?.status) {
      setPrice((price) => {
        return response?.priceETH;
      });
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Price ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
    setWaiting((waiting) => {
      return false;
    });
  };

  const getMaxPerMintTokensFromContract = async () => {
    setWaiting((waiting) => {
      return true;
    });
    const response = await maxPerMintTokens();
    // console.log("response", response);
    if (response?.status) {
      setMaxPerMint(response?.maxPerMint);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Max per mint ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
    setWaiting((waiting) => {
      return false;
    });
  };

  const getMaxGenCountFromContract = async () => {
    setWaiting((waiting) => {
      return true;
    });
    const response = await getMaxGenCount();
    // console.log("response Max Supply", response);
    if (response?.status) {
      setMaxGenCount(response?.maxGenCount);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Max Gen Count ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
    setWaiting((waiting) => {
      return false;
    });
  };

  const getPausedFromContract = async () => {
    setWaiting((waiting) => {
      return true;
    });
    const response = await getPausedStatus();
    if (response?.status) {
      setPaused(response?.pausedStatus);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Paused Status ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
    setWaiting((waiting) => {
      return false;
    });
  };

  const getPrivateSaleFromContract = async () => {
    setWaiting((waiting) => {
      return true;
    });
    const response = await getPrivateSaleStatus();
    // console.log("response", response);
    if (response?.status) {
      setPrivateSale(response?.privateSaleStatus);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Private Sale Status ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
    setWaiting((waiting) => {
      return false;
    });
  };

  const getPublicSaleFromContract = async () => {
    setWaiting((waiting) => {
      return true;
    });
    const response = await getPublicSaleStatus();
    //console.log("response", response);
    if (response?.status) {
      setPublicSale(response?.publicSaleStatus);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Public Sale Status ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
    setWaiting((waiting) => {
      return false;
    });
  };

  const getThronePassSaleFromContract = async () => {
    setWaiting((waiting) => {
      return true;
    });
    const response = await getThronePassSale();
    if (response?.status) {
      setThronePassSale(response?.thronePassSale);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Throne Pass ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
    setWaiting((waiting) => {
      return false;
    });
  };

  const getTotalSupplyFromContract = async () => {
    setWaiting((waiting) => {
      return true;
    });
    const response = await getTotalSupply();
    //console.log("response", response);
    if (response?.status) {
      setTotalSupply(response?.totalSupply);
    } else {
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Total Supply ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
    setWaiting((waiting) => {
      return false;
    });
  };

  const getBalanceOfFromContract = async () => {
    StartLoader(loaderType.MINT);
    let setBalance = "";

    const response = await getBalanceOf(changedAddress);
    const responseMax = await maxPerMintTokens();
    if (response?.status && responseMax.status) {
      StopLoader(loaderType.MINT);
      
      if (parseInt(response?.balance) >= parseInt(responseMax?.maxPerMint)) {
        setMintOnHold((mintOnHold) => {
          return true;
        });
        setHoldMessage((holdMessage) => {
          return "Congratulations! You’ve minted the maximum number of tokens assigned to you! Go to Opensea.io to confirm your tokens have landed in your wallet";
        });
      } else {
        setMintOnHold((mintOnHold) => {
          return false;
        });
        setQuantity((quantity) => {
          return 1;
        });
        setMaxPerMint((maxPerMint) => {
          return parseInt(responseMax?.maxPerMint) - parseInt(response?.balance);
        });
      }
    } else {
      StopLoader(loaderType.MINT);
      showNotification(
        notificationType.DANGER,
        `${metamaskErrorMessages.canNotGetError}Balance ${metamaskErrorMessages.dueTo}${metamaskErrorMessages.seeConsole}`,
        notificationSetting
      );
    }
  };

  const getTokenType = () => {
    unmount.current = false;
    setWaiting((waiting) => {
      return true;
    });
    CommonService.getTokenType({ wallet_address: changedAddress }).then(
      (response) => {
        if (unmount.current === true) return;
        if (response.status) {
          console.log("Entered in True");
          let tokenType = response?.data?.tokenTypeGet;
          setPhase((phase) => {
            return tokenType;
          });

          if (tokenType === mintPhase.GOLD || tokenType === mintPhase.SILVER) {
            setPrice((price) => {
              return response?.data?.price;
            });
            setMaxValues((maxValues) => {
              return {
                maxMints: response?.data?.max_mint,
                totalEthers: (
                  parseFloat(response?.data?.max_mint) *
                  parseFloat(response?.data?.price)
                ).toFixed(3),
                price: response?.data?.price,
              };
            });
          }

          if (
            tokenType !== mintPhase.PUBLIC &&
            tokenType !== mintPhase.WHITELIST_GENERAL &&
            tokenType !== mintPhase.WHITELIST_IAC
          ) {
            setQuantity((quantity) => {
              return response?.data?.max_mint;
            });
            setMaxPerMint((maxPerMint) => {
              return response?.data?.max_mint;
            });

            if (parseInt(response?.data?.max_mint) > 0) {
              setMintOnHold((mintOnHold) => {
                return false;
              });
            } else {
              setMintOnHold((mintOnHold) => {
                return true;
              });
              setHoldMessage((holdMessage) => {
                return "Thank you for being a part of Women Unite Journey!";
              });
            }
          } else {
            getBalanceOfFromContract();
          }
          setWaiting((waiting) => {
            return false;
          });
        } else {
          console.error("Error Getting Token Type: ", response.message);
          if (response?.msg_type !== "limit_exceed") {
            showNotification(
              notificationType.ERROR,
              response.message,
              notificationSetting
            );
          }

          setMintOnHold(true);
          setHoldMessage((holdMessage) => {
            return `${response.message}.`;
          });
          setWaiting((waiting) => {
            return false;
          });
        }
      }
    );
  };
  const setTotalAmountValues = (quantity) => {
    setWaiting((waiting) => {
      return true;
    });
    setTotalAmount((parseFloat(quantity) * parseFloat(price)).toFixed(3));
    setWaiting((waiting) => {
      return false;
    });
  };

  const manageCounter = (buttonType) => {
    const { min, max, value } = quantityRef.current;
    let newValue = manageCounterProcessing(buttonType, min, value, max);
    setQuantity(newValue);
    setTotalAmountValues(newValue);
  };

  const mintNFTPressed = async (event) => {
    event.preventDefault();

    let apiType = "";
    let type = saleType.PUBLIC_SALE;
    let mintFlag = false;
    // debugger;
    if (phase === mintPhase.GOLD || phase === mintPhase.SILVER) {
      if (thronePassSale !== throneType.indexOf(phase)) {
        mintFlag = false;
        showNotification(
          notificationType.ERROR,
          `Not Eligible to Mint, ${metamaskErrorMessages.dueToText} ${phase} Phase is Not Active`,
          {
            title: "Error!",
            duration: 6000,
            notificationPosition: notificationPosition.BOTTOM_RIGHT,
          }
        );
        return;
      } else if (thronePassSale > 0 && paused) {
        mintFlag = false;
        showNotification(
          notificationType.ERROR,
          `Minting is Currently Paused`,
          {
            title: "Error!",
            duration: 6000,
            notificationPosition: notificationPosition.BOTTOM_RIGHT,
          }
        );
        return;
      }
      mintFlag = true;
      type = saleType.THRONE_SALE;
      apiType =
        phase === mintPhase.GOLD
          ? sendStatusForMint.GOLD
          : phase === mintPhase.SILVER
          ? sendStatusForMint.SILVER
          : "";
    } else if (
      phase === mintPhase.TEAM
      // || phase === mintPhase.OWNER
    ) {
      if (thronePassSale > 0) {
        mintFlag = false;
        showNotification(
          notificationType.ERROR,
          `Currently Throne Pass Sale is Active`,
          {
            title: "Error!",
            duration: 6000,
            notificationPosition: notificationPosition.BOTTOM_RIGHT,
          }
        );
        return;
      } else {
        if (!privateSale && !paused) {
          mintFlag = false;
          showNotification(
            notificationType.ERROR,
            `Currently private sale is not active, Please try again later.`,
            {
              title: "Error!",
              duration: 6000,
              notificationPosition: notificationPosition.BOTTOM_RIGHT,
            }
          );
          return;
        } else if ((privateSale && paused) || (!privateSale && paused)) {
          mintFlag = false;
          showNotification(
            notificationType.ERROR,
            `Minting is Currently Paused`,
            {
              title: "Error!",
              duration: 6000,
              notificationPosition: notificationPosition.BOTTOM_RIGHT,
            }
          );
          return;
        }
        apiType = phase === mintPhase.TEAM ? sendStatusForMint.TEAM : "";
        mintFlag = true;
        type = saleType.PRIVATE_SALE;
      }
    } else if (
      phase === mintPhase.WHITELIST_GENERAL ||
      phase === mintPhase.WHITELIST_IAC ||
      phase === mintPhase.PUBLIC
    ) {
      if (thronePassSale > 0) {
        mintFlag = false;
        showNotification(
          notificationType.ERROR,
          `Currently Throne Pass Sale is Active`,
          {
            title: "Error!",
            duration: 6000,
            notificationPosition: notificationPosition.BOTTOM_RIGHT,
          }
        );
        return;
      } else {
        if (!publicSale && !paused) {
          mintFlag = false;
          showNotification(
            notificationType.ERROR,
            `Currently public sale is not active, Please try again later.`,
            {
              title: "Error!",
              duration: 6000,
              notificationPosition: notificationPosition.BOTTOM_RIGHT,
            }
          );
          return;
        } else if ((publicSale && paused) || (!publicSale && paused)) {
          mintFlag = false;
          showNotification(
            notificationType.ERROR,
            `Minting is Currently Paused`,
            {
              title: "Error!",
              duration: 6000,
              notificationPosition: notificationPosition.BOTTOM_RIGHT,
            }
          );
          return;
        }
        apiType =
          phase === mintPhase.WHITELIST_IAC
            ? sendStatusForMint.WHITELIST_IAC
            : phase === mintPhase.WHITELIST_GENERAL
            ? sendStatusForMint.WHITELIST_GENERAL
            : phase === mintPhase.PUBLIC
            ? sendStatusForMint.PUBLIC
            : "";
        mintFlag = true;
        type = saleType.PUBLIC_SALE;
      }
    }
    // console.log("MintFlag", mintFlag);
    if (mintFlag) {
      let mint_id;
      if (
        apiType !== sendStatusForMint.PUBLIC &&
        apiType !== sendStatusForMint.WHITELIST_GENERAL &&
        apiType !== sendStatusForMint.WHITELIST_IAC &&
        apiType !== ""
      ) {
        mint_id = await addMintEntry(apiType);
      }

      let processFlag = false;
      if (
        apiType !== sendStatusForMint.PUBLIC &&
        apiType !== sendStatusForMint.WHITELIST_GENERAL &&
        apiType !== sendStatusForMint.WHITELIST_IAC &&
        apiType !== "" &&
        mint_id !== "" &&
        mint_id !== null &&
        mint_id !== undefined
      ) {
        // console.log("Other mint");
        processFlag = true;
      } else if (
        apiType === sendStatusForMint.PUBLIC ||
        apiType !== sendStatusForMint.WHITELIST_GENERAL ||
        apiType !== sendStatusForMint.WHITELIST_IAC
      ) {
        // console.log("Public");
        processFlag = true;
      }

      // console.log("ProcessFlag:", processFlag);
      if (processFlag) {
        StartLoader(loaderType.MINT);
        const { status, message, hash } = await mintNFT(
          quantity,
          totalAmount,
          type
        );
        if (status) {
          StopLoader(loaderType.MINT);
          showNotification(notificationType.SUCCESS, message, {
            title: "Minting Successful",
            duration: 6000,
            notificationPosition: notificationPosition.BOTTOM_RIGHT,
          });
          if (phase !== mintPhase.GOLD && phase !== mintPhase.SILVER) {
            getBalanceOfFromContract();
          }
          if (
            phase !== mintPhase.GOLD &&
            phase !== mintPhase.SILVER &&
            phase !== mintPhase.TEAM
          ) {
            getTokenType();
          } else {
            manageMintEntry(apiType, "UPDATE", mint_id, hash);
          }
        } else {
          StopLoader(loaderType.MINT);
          showNotification(
            notificationType.DANGER,
            message,
            notificationSetting
          );
          if (
            phase !== mintPhase.GOLD &&
            phase !== mintPhase.SILVER &&
            phase !== mintPhase.TEAM
          ) {
            getTokenType();
          } else {
            manageMintEntry(apiType, "DELETE", mint_id, hash);
          }
        }
      }
      // let mint_id = "";
      // unmount.current = false;

      // console.log("Mint ID: ", mint_id);
    }
  };

  const addMintEntry = async (apiType) => {
    let mint_id;
    let tempObject = {
      wallet_address: changedAddress,
      count: quantity,
      status: 0,
      type: apiType,
      txn_hash: "",
    };
    await CommonService.addMint(tempObject).then((response) => {
      if (unmount.current === true) return;
      if (response.status) {
        mint_id = response?.data?.id;
      } else {
        console.error("Error Adding Data : ", response.message);
        showNotification(
          notificationType.ERROR,
          response.message,
          notificationSetting
        );
        mint_id = "";
      }
    });
    // console.log("ID:", mint_id);
    return mint_id;
  };

  const manageMintEntry = (apiType, operationType, mint_id, txnHash) => {
    // let status;
    let tempObject = {
      wallet_address: changedAddress,
      count: quantity,
      type: apiType,
      txn_hash: txnHash,
      status: operationType === "UPDATE" ? 1 : operationType === "DELETE" && 2,
      mint_id,
    };

    CommonService.addMint(tempObject).then((response) => {
      if (unmount.current === true) return;
      if (response.status) {
        // status = true;
        console.log(`Entry ${operationType}ed Hash:${txnHash} ID:${mint_id}`);
      } else {
        console.error("Error Adding Data : ", response.message);
        showNotification(
          notificationType.ERROR,
          response.message,
          notificationSetting
        );
        // status = false;
      }
      getTokenType();
    });
  };

  return (
    <>
      <header>
        <div className="top-icon for-desktop">
          <div className="container">
            <ul className="social-icon top-head">
              <li>
                <a
                  href="https://www.youtube.com/channel/UCbC26jObRCXZ7nEFhz5CRCw"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-youtube"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/womenunitenft/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://discord.com/invite/MG7C59rBwH"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-discord"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/WomenUniteNFT"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
              <i className="bi bi-x-lg"></i>
            </button>
            <Link className="navbar-brand for-mobile" to={routesList.indexPage}>
              <img src="images/logo.png" alt="" width="250" />
            </Link>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav mintblank">
                <a className="clickable"></a>
              </ul>
              <Link
                className="navbar-brand for-desktop"
                to={routesList.indexPage}
              >
                <img src="images/logo.png" alt="" width="250" />
              </Link>
              <ul className="navbar-nav collection_btn">
                <a className="btn clickable">View Collection</a>
              </ul>
              <div className="top-icon for-mobile">
                <ul className="social-icon top-head">
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCbC26jObRCXZ7nEFhz5CRCw"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/womenunitenft/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://discord.com/invite/MG7C59rBwH"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-discord"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/WomenUniteNFT"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* Banner */}
      <section className="section-pd minting-prices">
        <div className="container">
          <h2>Women Unite – 10k Assemble Collection</h2>
          <div className="sub-heading">Mint Your Tokens Now</div>
          <div className="mit-wrap">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <OwlSlider
                  className="mint-carousel"
                  {...{
                    loop: false,
                    margin: 0,
                    animateOut: "fadeOut",
                    autoplay: false,
                    mouseDrag: false,
                    autoplayTimeout: 2500,
                    nav: false,
                    dots: false,
                    responsive: {
                      0: {
                        items: 1,
                        mouseDrag: false,
                      },
                    },
                  }}
                >
                  <div className="item">
                    <img
                      className="slide-img"
                      src="images/WU-Minting-Animation.gif"
                      alt=""
                    />
                  </div>
                </OwlSlider>

                {/* <div className="owl-carousel mint-carousel mt-0">
                          <div className="team-item">
                            <img src="images/team1.png" alt="" title="" className="img-fluid" />
                          </div>
                          <div className="team-item">
                            <img src="images/team2.png" alt="" title="" className="img-fluid" />
                          </div> 
                        </div> */}
              </div>
              <div className="col-lg-8">
                <div className="mint-price">
                  <h3>Select How Many Tokens You Want and Click Mint</h3>
                  {/* <p>
                    You have minted the maximum token allocation assigned to
                    your wallet.
                  </p> */}
                  {!mintOnHold ? (
                    <form className="min-form">
                      <div className="counter-row">
                        <div className="inner-item">
                          <div className="input-counter">
                            {/* {phase !== mintPhase.GOLD &&
                              phase !== mintPhase.SILVER && ( */}
                            <button
                              type="button"
                              className="minus-btn"
                              id="minus-btn"
                              onClick={() => manageCounter("minus")}
                            >
                              <i className="bi bi-dash"></i>
                            </button>
                            {/* )} */}
                            <span>Qty.</span>
                            <input
                              type="text"
                              className="form-control"
                              id="quantity"
                              name="quantity"
                              value={quantity}
                              ref={quantityRef}
                              min={1}
                              max={maxPerMint}
                            />
                            {/* {phase !== mintPhase.GOLD &&
                              phase !== mintPhase.SILVER && ( */}
                            <button
                              type="button"
                              className="plus-btn"
                              id="plus-btn"
                              onClick={() => manageCounter("plus")}
                            >
                              <i className="bi bi-plus"></i>
                            </button>
                            {/* )} */}
                          </div>
                          <div className="input-form">
                            <span>Price (ETH)</span>
                            <input
                              type="text"
                              className="form-control"
                              value={totalAmount}
                              readOnly
                              onChange={() => {
                                console.log("");
                              }}
                            />
                          </div>
                        </div>
                        {isWalletConnected && waiting ? (
                          <button
                            className={"btn btn-dark mt-3"}
                            type={"button"}
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            {"Fetching Data! Please Wait..."}
                          </button>
                        ) : (
                          isWalletConnected && (
                            <MintButton
                              type={"button"}
                              className={"btn btn-dark mt-3"}
                              onClick={mintNFTPressed}
                            >
                              Mint!
                            </MintButton>
                          )
                        )}
                      </div>
                    </form>
                  ) : (
                    <div className="card m-2 p-2 text-center">
                      {holdMessage}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <p className="mt-4 text-center">
          Minting has been put on hold, please visit our discord server for the latest announcements
          </p>
        </div>
      </section>
    </>
  );
};

export default Mint;
