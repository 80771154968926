import React from "react";
import { labels } from "../Common";
import { GlobalParagraph as Paragraph } from "../Global";

const Infographic = () => {
  return (
    <>
      <img src="images/Women-Unite-Gamification.jpg" alt="" title="" className="img-fluid w-100" />
    </>
  );
};

export default Infographic;
