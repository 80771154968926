// require("dotenv").config();
import React from "react";
import { convertArrayFromArrayishString, saleType } from "../Common";
const { ethers } = require("ethers");

const contractABI =
  require(`../Contracts/${process.env.REACT_APP_CONTRACT_ABI_NAME}`).abi;
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
// const contractABI ="";
// const contractAddress = "";

export function getSmartContractReadFunctions() {
  const { ethereum } = window;
  if (ethereum) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    // const signer = provider.getSigner();
    const smartContract = new ethers.Contract(
      contractAddress,
      contractABI,
      provider
    );
    // console.log("functions", smartContract.functions);
    return { smartContract };
  }
}

export function getSmartContractWriteFunctions() {
  const { ethereum } = window;
  if (ethereum) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const smartContract = new ethers.Contract(
      contractAddress,
      contractABI,
      signer
    );
    // console.log("functions", smartContract.functions);
    return { provider, signer, smartContract };
  }
}

// Wallet Functions Starts Here
export const connectWallet = async () => {
  const { ethereum } = window;
  if (ethereum) {
    try {
      const { provider, signer } = getSmartContractWriteFunctions();
      await provider.send("eth_requestAccounts", []);
      const connectAddresses = await signer.getAddress();
      const balance = await signer.getBalance();
      const chainId = await signer.getChainId();
      let obj = {};
      if (connectAddresses.length > 0) {
        obj = {
          status: true,
          message: "Address Fetch Succesfully",
          address: connectAddresses,
          walletBalance: ethers.utils.formatEther(balance),
          chainId: chainId,
        };
      } else {
        obj = {
          status: false,
          message:
            "Address Not Fetch!, Connect to MetaMask by 'Connect to Wallet Button'",
          address: "",
          walletBalance: null,
          chainId: null,
        };
      }
      return obj;
    } catch (error) {
      return {
        status: false,
        address: "",
        walletBalance: null,
        chainId: null,
        message: error.message,
      };
    }
  } else {
    return {
      status: false,
      address: "",
      walletBalance: null,
      chainId: null,
      message: (
        <span>
          {" "}
          🦊{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://metamask.io/download.html`}
          >
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </span>
      ),
    };
  }
};

export const getcurrentlyConnectWallet = async () => {
  const { ethereum } = window;
  if (ethereum) {
    try {
      const { provider, signer } = getSmartContractWriteFunctions();
      await provider.send("eth_accounts", []);
      const connectAddresses = await signer.getAddress();
      const balance = await signer.getBalance();
      const chainId = await signer.getChainId();
      let obj = {};
      if (connectAddresses.length > 0) {
        obj = {
          status: true,
          message: "Address Fetch Succesfully",
          address: connectAddresses,
          walletBalance: ethers.utils.formatEther(balance),
          chainId: chainId,
        };
      } else {
        obj = {
          status: false,
          message:
            "Address Not Fetch!, Connect to MetaMask by Clicking 'Connect to Wallet' Button",
          address: "",
          walletBalance: null,
          chainId: null,
        };
      }
      return obj;
    } catch (error) {
      return {
        status: false,
        address: "",
        walletBalance: null,
        chainId: null,
        message: error.message,
      };
    }
  } else {
    return {
      status: false,
      address: "",
      walletBalance: null,
      chainId: null,
      message: (
        <span>
          {" "}
          🦊{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://metamask.io/download.html`}
          >
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </span>
      ),
    };
  }
};

export const getcurrentlyConnectChain = async () => {
  const { ethereum } = window;
  if (ethereum) {
    try {
      const chainDetails = await ethereum.on("chainChanged", (chainId) => {
        if (chainId.length > 0) {
          console.log("Chain ID Details is: ", chainId);
          window.location.reload();
        } else {
          console.log("Error in Chain ID");
        }
      });
    } catch (error) {
      console.log("Exception in Chain Changed: ", error);
    }
  } else {
    console.log("Error in Chain ID");
  }
};
// Wallet Functions Ends Here

// Contract Getter(Read) Functions Starts Here
export const getPrice = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let price = await smartContract.Price();
      return {
        status: true,
        price: ethers.utils.formatUnits(price, "wei"),
        priceETH: ethers.utils.formatEther(price),
      };
    } else {
      console.log(`Cannot get Price`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getBalanceOf = async (address) => {
  try {
    const { ethereum } = window;

    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let balanceOf = await smartContract.balanceOf(address);
      let formattedBalance = balanceOf.toNumber();
      if (formattedBalance !== undefined) {
        return { status: true, balance: formattedBalance };
      }
      return { status: false, balance: "" };
    } else {
      console.log(`Not Found Balance`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return { status: false, balance: "", error: error?.message };
  }
};

export const getBaseURI = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let baseURI = await smartContract.baseURI();
      return { status: true, baseURI };
    } else {
      console.log(`Cannot get baseURI`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getApproved = async (noOfTokens) => {
  try {
    const { ethereum } = window;

    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let approved = await smartContract.getApproved(noOfTokens);
      // let formattedBalance = balanceOf.toNumber();
      if (approved !== undefined) {
        return { status: true, address: approved };
      }
      return { status: false, address: "" };
    } else {
      console.log(`Not Responding`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return { status: false, address: "", error: error?.message };
  }
};

export const isApprovedForAll = async (ownerAddress, operatorAddress) => {
  try {
    const { ethereum } = window;

    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let approved = await smartContract.isApprovedForAll(
        ownerAddress,
        operatorAddress
      );
      // let formattedBalance = balanceOf.toNumber();
      if (approved !== undefined) {
        return { status: true, isApprovedForAll: approved };
      }
      return { status: false, isApprovedForAll: "" };
    } else {
      console.log(`Not Responding`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return { status: false, isApprovedForAll: "", error: error?.message };
  }
};

export const maxPerMintTokens = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let maxPerMint = await smartContract.maxMintTokens();
      return {
        status: true,
        maxPerMint: parseInt(maxPerMint._hex, 16),
      };
    } else {
      console.log(`Cannot get Max Per Mint Tokens`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const maxSupply = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let maxMints = await smartContract.maximumSupply();
      return {
        status: true,
        maxMints: parseInt(maxMints._hex, 16),
      };
    } else {
      console.log(`Cannot get Price`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getContractName = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let contractName = await smartContract.name();
      return {
        status: true,
        contractName,
      };
    } else {
      console.log(`Cannot get Name`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getnotRevealedUri = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let notRevealedURI = await smartContract.notRevealedUri();
      return {
        status: true,
        notRevealedURI,
      };
    } else {
      console.log(`Cannot get notRevealedURI`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getOwner = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let ownerAddress = await smartContract.owner();
      return {
        status: true,
        ownerAddress,
      };
    } else {
      console.log(`Cannot get owner`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getOwnerOf = async (token) => {
  try {
    const { ethereum } = window;

    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let ownerOfToken = await smartContract.ownerOf(token);
      // let formattedBalance = balanceOf.toNumber();
      if (ownerOfToken !== undefined) {
        return { status: true, ownerOfToken };
      }
      return { status: false, ownerOfToken: "" };
    } else {
      console.log(`Not Responding`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return { status: false, ownerOfToken: "", error: error?.message };
  }
};

export const getPausedStatus = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let pausedStatus = await smartContract.paused();
      return {
        status: true,
        pausedStatus,
      };
    } else {
      console.log(`Cannot get Pause Status`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getPrivateSaleStatus = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let privateSaleStatus = await smartContract.privateSale();
      return {
        status: true,
        privateSaleStatus,
      };
    } else {
      console.log(`Not Found Private Sale Status`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getPublicSaleStatus = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let publicSaleStatus = await smartContract.publicSale();
      return {
        status: true,
        publicSaleStatus,
      };
    } else {
      console.log(`Cannot get Public Sale Status`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getRevealedStatus = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let revealedStatus = await smartContract.revealed();
      return {
        status: true,
        revealedStatus,
      };
    } else {
      console.log(`Cannot get Revealed Status`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getSupportsInterfaceStatus = async (interfaceId) => {
  // try {
  //   const { ethereum } = window;
  //   if (ethereum) {
  //     const { smartContract } = getSmartContractReadFunctions();
  //     let supportsInterfaceStatus = await smartContract.supportsInterface(
  //       interfaceId
  //     );
  //     return {
  //       status: true,
  //       supportsInterfaceStatus,
  //     };
  //   } else {
  //     console.log(`Cannot get supportsInterface Status`);
  //   }
  //   return null;
  // } catch (error) {
  //   console.error(error);
  //   return { status: false, message:
  // error?.message?.length > 150
  // ? `${error?.message?.substring(0, 150)}...`
  // : error?.message, hash:"" };
  // }
};

export const getSymbol = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let symbol = await smartContract.symbol();
      return {
        status: true,
        symbol,
      };
    } else {
      console.log(`Cannot get Symbol`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getTeamListed = async (address) => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let teamListedResponse = await smartContract.teamListed(address);
      let teamListed = teamListedResponse.toNumber();
      return {
        status: true,
        teamListed,
      };
    } else {
      console.log(`Cannot get Team Listed`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getTokenByIndex = async (index) => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let tokenByIndexResponse = await smartContract.tokenByIndex(index);
      let tokenByIndex = tokenByIndexResponse.toNumber();
      return {
        status: true,
        tokenByIndex,
      };
    } else {
      console.log(`Cannot get Token By Index`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getTokenOfOwnerByIndex = async (address, index) => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let tokenOfOwnerByIndexResponse = await smartContract.tokenOfOwnerByIndex(
        address,
        index
      );
      let tokenOfOwnerByIndex = tokenOfOwnerByIndexResponse.toNumber();
      return {
        status: true,
        tokenOfOwnerByIndex,
      };
    } else {
      console.log(`Cannot get Token Of Owner By Index`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getTokenURI = async (tokenId) => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let tokenURI = await smartContract.tokenURI(tokenId);
      return {
        status: true,
        tokenURI,
      };
    } else {
      console.log(`Cannot get Token URI`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getTotalSupply = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let totalSupplyResponse = await smartContract.totalSupply();
      let totalSupply = totalSupplyResponse.toNumber();
      return {
        status: true,
        totalSupply,
      };
    } else {
      console.log(`Cannot get Total Supply`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getWalletOfOwner = async (address) => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let walletOfOwner = await smartContract.walletOfOwner(address);
      // console.log("Total Tokens of Owner: ", walletOfOwner.length);
      let walletOfOwnerArray = [];
      for (let i = 0; i < walletOfOwner.length; i++) {
        walletOfOwnerArray.push(walletOfOwner[i].toNumber());
      }
      return { status: true, walletOfOwnerArray };
    } else {
      console.log(`Not Found Owner of Wallet`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const isWhitelisted = async (address) => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let whitelisted = await smartContract.whitelisted(address);
      return { status: true, whitelisted };
    } else {
      console.log(`Not Found Whitelisted Users`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getBaseExtension = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let baseExtension = await smartContract.baseExtension();
      return {
        status: true,
        baseExtension,
      };
    } else {
      console.log(`Cannot get Price`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getMaxGenCount = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let maxGenCount = await smartContract.maxGenCount();
      return {
        status: true,
        maxGenCount: parseInt(maxGenCount._hex, 16),
      };
    } else {
      console.log(`Cannot get Price`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const renounceOwnershipStatus = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let renounceOwnership = await smartContract.renounceOwnership();
      return {
        status: true,
        renounceOwnership,
      };
    } else {
      console.log(`Cannot get Price`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getGoldMinted = async (address) => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let goldMintedStatus = await smartContract.GoldMinted(address);
      let goldMinted = goldMintedStatus.toNumber();
      return { status: true, goldMinted };
    } else {
      console.log(`Not Found goldMinted`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getSilverMinted = async (address) => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let silverMintedStatus = await smartContract.SilverMinted(address);
      let silverMinted = silverMintedStatus.toNumber();
      return { status: true, silverMinted };
    } else {
      console.log(`Not Found silverMinted`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const getThronePassSale = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractReadFunctions();
      let thronePassSaleStatus = await smartContract.getThronePassSale();
      let thronePassSale = thronePassSaleStatus.toNumber();
      return { status: true, thronePassSale };
    } else {
      console.log(`Not Found thronePassSale`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};
// Contract Getter(Read) Functions Ends Here

// Contract Setter(Write) Functions Starts Here

export const approve = async (address, noOfTokens) => {
  let obj = {};
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let nftTxn;
      nftTxn = await smartContract.approve(address, noOfTokens);

      console.log("Approving... Please Wait");
      await nftTxn.wait();
      console.log(
        `Approved, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
      );

      obj = {
        status: true,
        message: `Approved, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
        hash: nftTxn.hash,
      };
    } else {
      obj = {
        status: false,
        message: `Not Approved Transaction`,
        hash: "",
      };
      console.log(`Not Approved Transaction`);
    }
  } catch (error) {
    obj = {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
  return obj;
};

export const changePrivateSaleStatus = async (value) => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();
      let privateSaleStarted = await smartContract.privateSaleStarted(value);
      console.log("Updating... Please Wait");
      await privateSaleStarted.wait();
      return { status: true, privateSaleStarted };
    } else {
      console.log(`Private Sale Status Not Changed`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const changePublicSaleStatus = async (value) => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let publicSaleStarted = await smartContract.publicSaleStarted(value);
      console.log("Updating... Please Wait");
      await publicSaleStarted.wait();
      return { status: true, publicSaleStarted };
    } else {
      console.log(`Public Sale Status Not Changed`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const removeWhitelistUser = async (address) => {
  let obj = {};
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let nftTxn;
      nftTxn = await smartContract.removeWhitelistUser(address);

      console.log("Removing... Please Wait");
      await nftTxn.wait();
      console.log(
        `Removed, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
      );

      obj = {
        status: true,
        message: `Removed, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
        hash: nftTxn.hash,
      };
    } else {
      obj = {
        status: false,
        message: `Not Removed Transaction`,
        hash: "",
      };
      console.log(`Not Removed Transaction`);
    }
  } catch (error) {
    obj = {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
  return obj;
};

export const changeRevealStatus = async (value) => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();
      let revealedStatus = await smartContract.reveal(value);
      console.log("Changing Status... Please Wait");
      await revealedStatus.wait();
      return { status: true, revealedStatus };
    } else {
      console.log(`Not Revealed`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const safeTransferFrom = async (fromAddress, toAddress, tokenId) => {
  // let obj = {};
  // try {
  //   const { ethereum } = window;
  //   if (ethereum) {
  //     const { smartContract } = getSmartContractWriteFunctions();
  //     let nftTxn;
  //     nftTxn = await smartContract.safeTransferFrom(
  //       fromAddress,
  //       toAddress,
  //       tokenId
  //     );
  //     console.log("Safe Transferring... Please Wait");
  //     await nftTxn.wait();
  //     console.log(
  //       `Safe Transferred, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
  //     );
  //     obj = {
  //       status: true,
  //       message: `Safe Transferred, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
  //       hash: nftTxn.hash,
  //     };
  //   } else {
  //     obj = {
  //       status: false,
  //       message: `Safe Transfer Not Done`,
  //       hash: "",
  //     };
  //     console.log(`Safe Transfer Not Done`);
  //   }
  // } catch (error) {
  //   obj = {
  //     status: false,
  //     message:
  //       error?.message?.length > 150
  //         ? `${error?.message?.substring(0, 150)}...`
  //         : error?.message,
  //     hash: "",
  //   };
  // }
  // return obj;
};

export const safeTransferFromBytes = async (
  fromAddress,
  toAddress,
  tokenId,
  bytes
) => {
  // let obj = {};
  // try {
  //   const { ethereum } = window;
  //   if (ethereum) {
  //     const { smartContract } = getSmartContractWriteFunctions();
  //     let nftTxn;
  //     nftTxn = await smartContract.safeTransferFrom(
  //       fromAddress,
  //       toAddress,
  //       tokenId,
  //       bytes
  //     );
  //     console.log("Safe Transferring... Please Wait");
  //     await nftTxn.wait();
  //     console.log(
  //       `Safe Transferred, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
  //     );
  //     obj = {
  //       status: true,
  //       message: `Safe Transferred, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
  //       hash: nftTxn.hash,
  //     };
  //   } else {
  //     obj = {
  //       status: false,
  //       message: `Safe Transfer Not Done`,
  //       hash: "",
  //     };
  //     console.log(`Safe Transfer Not Done`);
  //   }
  // } catch (error) {
  //   obj = {
  //     status: false,
  //     message:
  //       error?.message?.length > 150
  //         ? `${error?.message?.substring(0, 150)}...`
  //         : error?.message,
  //     hash: "",
  //   };
  // }
  // return obj;
};

export const setApprovalForAll = async (address, value) => {
  let obj = {};
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let nftTxn;
      nftTxn = await smartContract.setApprovalForAll(address, value);

      console.log("Approving For All... Please Wait");
      await nftTxn.wait();
      console.log(
        `Approved, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
      );

      obj = {
        status: true,
        message: `Approved, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
        hash: nftTxn.hash,
      };
    } else {
      obj = {
        status: false,
        message: `Not Approved Transaction`,
        hash: "",
      };
      console.log(`Not Approved Transaction`);
    }
  } catch (error) {
    obj = {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
  return obj;
};

export const setBaseURI = async (value) => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();
      let setBaseURIStatus = await smartContract.setBaseURI(value);
      await setBaseURIStatus.wait();
      console.log("Setting New Base URI... Please Wait!");
      return { status: true, setBaseURIStatus };
    } else {
      console.log(`Not Set BaseURI`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const setNotRevealedURI = async (value) => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();
      let setNotRevealedURIStatus = await smartContract.setNotRevealedURI(
        value
      );
      await setNotRevealedURIStatus.wait();
      console.log("Setting Not Revealed URI... Please Wait!");
      return { status: true, setNotRevealedURIStatus };
    } else {
      console.log(`Not Set RevealedURI`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const setPrice = async (newPrice) => {
  let obj = {};
  console.log("New Price: ", ethers.utils.parseEther(newPrice.toString()));
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let nftTxn;
      nftTxn = await smartContract.setPrice(
        ethers.utils.parseEther(newPrice.toString())
      );

      console.log("Setting New Price... Please Wait");
      await nftTxn.wait();
      console.log(
        `New Price Has been set, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
      );

      obj = {
        status: true,
        message: `New Price Has been set, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
        hash: nftTxn.hash,
      };
    } else {
      obj = {
        status: false,
        message: `New Price Not Set`,
        hash: "",
      };
      console.log(`New Price Not Set`);
    }
  } catch (error) {
    obj = {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
  return obj;
};

export const setSupplyLimit = async (supplyLimit) => {
  let obj = {};
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let nftTxn;
      nftTxn = await smartContract.setSupplyLimit(supplyLimit);

      console.log("Setting Supply Limit... Please Wait");
      await nftTxn.wait();
      console.log(
        `Supply Limit Has been set, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
      );

      obj = {
        status: true,
        message: `Supply Limit Has been set, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
        hash: nftTxn.hash,
      };
    } else {
      obj = {
        status: false,
        message: `Supply Limit Not Set`,
        hash: "",
      };
      console.log(`Supply Limit Not Set`);
    }
  } catch (error) {
    obj = {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
  return obj;
};

export const setThronePassSale = async (thronePass) => {
  let obj = {};
  console.log("Current Value", thronePass);
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let nftTxn;
      nftTxn = await smartContract.setThronePassSale(thronePass);

      console.log("Setting Throne Pass... Please Wait");
      await nftTxn.wait();
      console.log(
        `Throne Pass Has been set, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
      );

      obj = {
        status: true,
        message: `Throne Pass Has been set, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
        hash: nftTxn.hash,
      };
    } else {
      obj = {
        status: false,
        message: `Throne Pass Not Set`,
        hash: "",
      };
      console.log(`Throne Pass Not Set`);
    }
  } catch (error) {
    obj = {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
  return obj;
};

export const setmaxMintTokens = async (maxTokens) => {
  let obj = {};
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let nftTxn;
      nftTxn = await smartContract.setmaxMintTokens(maxTokens);

      console.log("Setting Max Tokens... Please Wait");
      await nftTxn.wait();
      console.log(
        `Max Tokens Has been set, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
      );

      obj = {
        status: true,
        message: `Max Tokens Has been set, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
        hash: nftTxn.hash,
      };
    } else {
      obj = {
        status: false,
        message: `Max Tokens Not Set`,
        hash: "",
      };
      console.log(`Max Tokens Not Set`);
    }
  } catch (error) {
    obj = {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
  return obj;
};

export const transferFrom = async (fromAddress, toAddress, tokens) => {
  let obj = {};
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();
      let nftTxn;
      nftTxn = await smartContract.transferFrom(fromAddress, toAddress, tokens);
      console.log("Transferring... Please Wait");
      await nftTxn.wait();
      console.log(
        `Transferred, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
      );
      obj = {
        status: true,
        message: `Transferred, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
        hash: nftTxn.hash,
      };
    } else {
      obj = {
        status: false,
        message: `Transfer Not Done`,
        hash: "",
      };
      console.log(`Transfer Not Done`);
    }
  } catch (error) {
    obj = {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
  return obj;
};

export const transferOwnership = async (address) => {
  let obj = {};
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let nftTxn;
      nftTxn = await smartContract.transferOwnership(address);

      console.log("Transferring Ownership... Please Wait");
      await nftTxn.wait();
      console.log(
        `Transfered, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
      );

      obj = {
        status: true,
        message: `Transfered, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
        hash: nftTxn.hash,
      };
    } else {
      obj = {
        status: false,
        message: `Not Transfered Ownership`,
        hash: "",
      };
      console.log(`Not Transfered Ownership`);
    }
  } catch (error) {
    obj = {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
  return obj;
};

export const withdraw = async () => {
  let obj = {};
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let nftTxn;
      nftTxn = await smartContract.withdraw();

      console.log("Withdrawing... Please Wait");
      await nftTxn.wait();
      console.log(
        `Withdrawn, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
      );

      obj = {
        status: true,
        message: `Withdrawn, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
        hash: nftTxn.hash,
      };
    } else {
      obj = {
        status: false,
        message: `Not Withdrawn Transaction`,
        hash: "",
      };
      console.log(`Not Withdrawn Transaction`);
    }
  } catch (error) {
    obj = {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
  return obj;
};

export const whiteListUsers = async (addressArray) => {
  let obj = {};

  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let nftTxn;
      nftTxn = await smartContract.whitelistUser(addressArray);

      console.log("Whitelisting... Please Wait");
      await nftTxn.wait();
      console.log(`Whitelisted, Kindly Refresh the Page`);

      obj = {
        status: true,
        message: `Whitelisted, Kindly Refresh the Page`,
        hash: nftTxn.hash,
      };
    } else {
      obj = {
        status: false,
        message: `Not Whitelisted`,
        hash: "",
      };
      console.log(`Not Whitelisted`);
    }
  } catch (error) {
    obj = {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
  return obj;
};

export const throneMint = async (noOfTokens) => {
  let obj = {};
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let nftTxn;
      console.log("Throne Mint Section");
      nftTxn = await smartContract.Throne_Mint(noOfTokens);

      console.log("Mining... Please Wait");
      await nftTxn.wait();
      console.log(
        `Mined, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
      );

      obj = {
        status: true,
        message: `Mined, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
        hash: nftTxn.hash,
      };
    } else {
      obj = {
        status: false,
        message: `Not Mined Transaction`,
        hash: "",
      };
      console.log(`Not Mined Transaction`);
    }
  } catch (error) {
    obj = {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
  return obj;
};

export const privateMint = async (noOfTokens, totalEthers) => {
  let obj = {};
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let nftTxn;
      console.log("Private Sale Section");
      nftTxn = await smartContract.PrivateMint(noOfTokens, {
        value: ethers.utils.parseEther(totalEthers.toString()),
      });

      console.log("Mining... Please Wait");
      await nftTxn.wait();
      console.log(
        `Mined, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
      );

      obj = {
        status: true,
        message: `Mined, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
        hash: nftTxn.hash,
      };
    } else {
      obj = {
        status: false,
        message: `Not Mined Transaction`,
        hash: "",
      };
      console.log(`Not Mined Transaction`);
    }
  } catch (error) {
    obj = {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
  return obj;
};

export const standardMint = async (noOfTokens, totalEthers) => {
  let obj = {};
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let nftTxn;
      console.log("Standard Sale Section");
      nftTxn = await smartContract.mint(noOfTokens, {
        value: ethers.utils.parseEther(totalEthers.toString()),
      });

      console.log("Mining... Please Wait");
      await nftTxn.wait();
      console.log(
        `Mined, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
      );

      obj = {
        status: true,
        message: `Mined, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
        hash: nftTxn.hash,
      };
    } else {
      obj = {
        status: false,
        message: `Not Mined Transaction`,
        hash: "",
      };
      console.log(`Not Mined Transaction`);
    }
  } catch (error) {
    obj = {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
  return obj;
};

export const pause = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let pauseStatus = await smartContract.pause();
      console.log("Pausing... Please Wait");
      await pauseStatus.wait();
      return { status: true, pauseStatus };
    } else {
      console.log(`Paused`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};

export const unPause = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let unpauseStatus = await smartContract.unpause();
      console.log("Unpausing... Please Wait");
      await unpauseStatus.wait();
      return { status: true, unpauseStatus };
    } else {
      console.log(`Paused`);
    }
    return null;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
};
// Contract Setter(Write) Functions Ends Here

// Mint Page Functions
export const mintNFT = async (noOfTokens, totalEthers, mintSaleType) => {
  let obj = {};
  try {
    const { ethereum } = window;
    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();

      let nftTxn;
      if (mintSaleType === saleType.THRONE_SALE) {
        console.log("Throne Sale Section");
        nftTxn = await smartContract.Throne_Mint(noOfTokens, {
          value: ethers.utils.parseEther(totalEthers),
        });
      } else if (mintSaleType === saleType.PRIVATE_SALE) {
        console.log("Private Sale Section");
        nftTxn = await smartContract.PrivateMint(noOfTokens, {
          value: ethers.utils.parseEther(totalEthers),
        });
      } else if (mintSaleType === saleType.PUBLIC_SALE) {
        console.log("Public Sale Section",totalEthers);
        nftTxn = await smartContract.mint(noOfTokens, {
          value: ethers.utils.parseEther(totalEthers),
        });
      }

      console.log("Mining... Please Wait");
      await nftTxn.wait();
      console.log(
        `Mined, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`
      );

      obj = {
        status: true,
        message: `Mined, See transaction on OpenSea. Transaction Hash: ${nftTxn.hash}`,
        hash: nftTxn.hash,
      };
    } else {
      obj = {
        status: false,
        message: `Not Mined Transaction`,
        hash: "",
      };
      console.log(`Not Mined Transaction`);
    }
  } catch (error) {
    obj = {
      status: false,
      message:
        error?.message?.length > 150
          ? `${error?.message?.substring(0, 150)}...`
          : error?.message,
      hash: "",
    };
  }
  return obj;
};

export const totalValues = async () => {
  try {
    const { ethereum } = window;
    let total = {
      maxMints: 0,
      totalRemaining: 0,
      maxPerMint: 0,
      price: null,
    };

    if (ethereum) {
      const { smartContract } = getSmartContractWriteFunctions();
      // console.log("functions", smartContract.functions);
      let maxMints = await smartContract.maxGenCount();
      let totalRemaining = await smartContract.totalSupply();
      let maxPerMint = await smartContract.maxMintTokens();
      let price = await smartContract.Price();
      total.maxMints = parseInt(maxMints._hex, 16);
      total.totalRemaining = parseInt(totalRemaining._hex, 16);
      total.maxPerMint = parseInt(maxPerMint._hex, 16);
      total.price = ethers.utils.formatEther(price);
    } else {
      console.log(`Not Mined Transaction`);
    }
    return total;
  } catch (error) {
    console.error(error);
  }
};

// Mint Page Functios Ends Here
