import axios from "axios";
import { ErrorManagement } from "./ErrorManagement";
import { loaderType, StartLoader, StopLoader } from "../Common";

let loader ;
// = loaderType.BUTTON;

export function SwitchLoader(type) {
  loader = type;
}

const Instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

Instance.interceptors.request.use(
  (config) => {
    StartLoader(loader);
    config.headers["From"] = "web";
    // debugger;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Instance.interceptors.response.use(
  (response) => {
    StopLoader(loader);
    // debugger;
    return response.data;
  },
  (error) => {
    StopLoader(loader);
    // debugger;
    ErrorManagement(error);
    return Promise.reject(error);
  }
);

export default Instance;
