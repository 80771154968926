/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  labels,
  notificationSetting,
  notificationType,
  routesList,
  showNotification,
} from "../Common";
import { GlobalModal } from "../Global/GlobalModal";
import { CommonService } from "../Services/CommonService";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [modal, setModal] = useState(false);
  const [subscribeFrameModal, setSubscribeFrameModal] = useState(false);

  const handleSubscribe = (event) => {
    event.preventDefault();
    if (email !== "") {
      CommonService.addSubscribe({ email }).then((response) => {
        if (response.status) {
          //   console.log("Entered in DB:", response);
          setModal(true);
          setEmail("");
        } else {
          showNotification(
            notificationType.DANGER,
            response?.message,
            notificationSetting
          );
        }
      });
    } else {
      showNotification(
        notificationType.DANGER,
        `Please Enter the Email ID`,
        notificationSetting
      );
    }
  };

  return (
    <>
      <footer className="main-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 text-mb-center">
              {/* <form className="newsletter">
                <label>To Get In First On Future Drops Join The List</label>
                <div
                  className="row d-flex
                                justify-content-center
                                align-items-center rows"
                >
                  <div className="input-group">
                    <input
                      type="email"
                      id={"email"}
                      name={"email"}
                      value={email}
                      className="form-control"
                      placeholder="Email Address"
                      onChange={(event) => setEmail(event.target.value)}
                      required
                    />
                    <button
                      className="btn btn-success
                                        border-rad"
                      onClick={handleSubscribe}
                      type="submit"
                      id="button-addon2"
                    >
                      <i
                        className="bi
                                            bi-arrow-right"
                      ></i>
                    </button>
                  </div>
                </div>
              </form> */}
              <a
                className={"btn subscribe-frame-button"}
                onClick={() => setSubscribeFrameModal(true)}
              >
                Subscribe Now!
              </a>
            </div>
            <div className="col-lg-4 footer_logo text-center">
              <img
                src="images/footer-logo.png"
                alt="Logo"
                title="Logo"
                width="250"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-4">
              <ul className="social-icon">
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCbC26jObRCXZ7nEFhz5CRCw"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-youtube"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/womenunitenft/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://discord.com/invite/MG7C59rBwH"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-discord"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/WomenUniteNFT"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-twitter"></i>
                  </a>
                </li>
                <li>
                <a
                  href="https://medium.com/@womenunitenft"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-medium"></i>
                </a>
              </li>
              </ul>
              <p>© I A V Global Events Organizers 2022</p>
              <ul className="footer-links">
                <li>
                  <a
                    href="https://iamverse.com/home/contact-us/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://iamverse.com/home/my-faqs/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    FAQ's
                  </a>
                </li>
                <li>
                  <a
                    href="https://creator.amniktechnologies.com/terms-and-conditions/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="https://creator.amniktechnologies.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
              <div className="powerBy">
                {/* WU is powered by{" "} */}
                <a
                  href="https://iamverse.com/home/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="images/i_am_creator.png" alt="" title="" />
                </a>
              </div>
            </div>
          </div>
          {/* <div className="copyright">
            Developed by{" "}
            <a
              href="https://semidotinfotech.com/"
              target="_blank"
              rel="noreferrer"
            >
              Semidot Infotech Pvt Ltd
            </a>
          </div> */}
        </div>
      </footer>

      {modal && (
        <GlobalModal
          id={"subscribe-modal"}
          isOpen={modal}
          isCloseButtonDisplay={true}
          isHeaderDisplay={true}
          modalClassName={"join-wu-modal"}
          dialogClassName={"modal-dialog-centered modal-lg"}
          modalId={"subscribe"}
          onModalClose={(e) => setModal(false)}
        >
          <div className="join-body">
            <div className="sub-icon">
              <img src="images/sub-mail.jpg" alt="" />
            </div>
            <div className="join-title">Thank You</div>
            <div className="sub-title">Stay tuned for upcoming events</div>
          </div>
        </GlobalModal>
      )}
      {subscribeFrameModal && (
        <GlobalModal
          id={"subscribe-modal"}
          isOpen={subscribeFrameModal}
          isCloseButtonDisplay={true}
          isHeaderDisplay={true}
          modalClassName={"join-wu-modal subscribe-frame-modal"}
          dialogClassName={"modal-dialog-centered"}
          modalId={"subscribe"}
          onModalClose={(e) => setSubscribeFrameModal(false)}
        >
          <iframe
            width={"100%"}
            height={"100%"}
            title={"Subscribe"}
            src="https://xr194.infusionsoft.com/app/form/womenunite-newsletter"
          ></iframe>
        </GlobalModal>
      )}
    </>
  );
};

export default Footer;
