import React, { useEffect, useState } from "react";
import { MintButtonService } from "../Services";

export const MintButton = React.forwardRef((props, ref) => {
  const [active, setActive] = useState();
  useEffect(() => {
    setActive(true);
    return () => {
      setActive(true);
    };
  }, []);

  MintButtonService.loadingState().subscribe((message) => {
    setActive(message);
  });

  return active && props && props !== "" ? (
    <>
      <button
        type={"submit"}
        className={props.className || "btn btn-dark mt-3"}
        {...props}
      >
        {props.label}
        {props.children}
      </button>
    </>
  ) : (
    <>
      <button className={"btn btn-dark mt-3"} disabled {...props}>
        <span
          className="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"
        ></span>
        {"Processing! Please Wait..."}
      </button>
      <div className={"card mt-2 p-2 text-center bg-danger bg-gradient text-white fw-bold"}>Please Don't Refresh or Close The Page, During Transaction</div>
    </>
  );
});
