import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export const OwlSlider = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <>
      <OwlCarousel {...props}>{children}</OwlCarousel>
    </>
  );
});
