/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routesList } from "../Common";
import { OwlSlider } from "../Common/OwlSlider";
import { SlickSlider } from "../Common/SlickSlider";
import useWalletChange from "../hooks/useWalletChange";

const IndexPage = () => {
  const { isWalletConnected, changedAddress } = useWalletChange();
  return (
    <>
      <div className="text-center pt-3 pb-3">
        <Link to={routesList.indexPage}>
          <img
            src="images/logo.png"
            alt="Logo"
            title="Logo"
            width="250"
            className="img-fluid"
          />
        </Link>
      </div>

      <section className="welcomeRow">
        <div className="sideAction">
          <h1>
            Welcome to <br />
            the wu kingdom
          </h1>
          <Link className="btn clickable" to={routesList.homePage}>
            Enter
          </Link>
        </div>
        <div className="clouds">
          <div className="clouds-1"></div>
        </div>
        <img className="wel-home" src="images/WU-palace-Final.gif" alt="" />
      </section>

      <div className="height15"></div>

      <section className="womanRow">
        <SlickSlider
          className="wu-carousel"
          {...{
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
            draggable: true,
            infinite: true,
            autoplaySpeed: 0,
            speed: 6000,
            pauseOnHover: false,
            arrows: false,
            cssEase: "linear",
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
            ],
          }}
        >
          <div className="wu-item">
            <img src="images/wu1.jpg" alt="" title="" className="img-fluid" />
          </div>
          <div className="wu-item">
            <img src="images/wu2.jpg" alt="" title="" className="img-fluid" />
          </div>
          <div className="wu-item">
            <img src="images/wu3.jpg" alt="" title="" className="img-fluid" />
          </div>
          <div className="wu-item">
            <img src="images/wu4.jpg" alt="" title="" className="img-fluid" />
          </div>
          <div className="wu-item">
            <img src="images/wu5.jpg" alt="" title="" className="img-fluid" />
          </div>
          <div className="wu-item">
            <img src="images/wu6.jpg" alt="" title="" className="img-fluid" />
          </div>
          <div className="wu-item">
            <img src="images/wu7.jpg" alt="" title="" className="img-fluid" />
          </div> 
          <div className="wu-item">
            <img src="images/wu8.jpg" alt="" title="" className="img-fluid" />
          </div>
          <div className="wu-item">
            <img src="images/wu9.jpg" alt="" title="" className="img-fluid" />
          </div>
          <div className="wu-item">
            <img src="images/wu10.jpg" alt="" title="" className="img-fluid" />
          </div>
          <div className="wu-item">
            <img src="images/wu11.jpg" alt="" title="" className="img-fluid" />
          </div> 
        </SlickSlider>
        <div className="wu-carousel"></div>
      </section>

      <section className="pt-4 pb-4 wu-info">
        <div className="container">
          <p>
            A limited NFT collection where the token itself doubles as your
            membership to the WU Kingdom. <br />
            The doors are open. join the community.
          </p>
        </div>
      </section>
    </>
  );
};

export default IndexPage;
