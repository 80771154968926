import { errorMessages, notificationType, showNotification } from "../Common";
import { notificationSetting } from "../Common";
import { routesList } from "./URLs";

const errorFourHoundred = (response) => {
  if (response.data) {
    let data = response.data;
    Object.values(data).map((value) =>
      showNotification(notificationType.DANGER, value, notificationSetting)
    );
  } else {
    showNotification(
      notificationType.DANGER,
      response.data.emailnotfound,
      notificationSetting
    );
  }
};

const errorFourNotOne = () => {
  showNotification(
    notificationType.DANGER,
    errorMessages.fourNotOne,
    notificationSetting
  );
  // Auth.signOut();
  // if (!is_server()) {
  //   window.location.href = routesList.indexPage;
  // }
};

const errorFourNotTwo = () => {
  showNotification(
    notificationType.DANGER,
    errorMessages.fourNotTwo,
    notificationSetting
  );
  // Auth.signOut();
  // if (!is_server()) {
    // window.location.href = routesList.indexPage;
  // }
};

const errorFourNotThree = () => {
  showNotification(
    notificationType.DANGER,
    errorMessages.fourNotThree,
    notificationSetting
  );
};

const errorFourNotFour = () => {
  showNotification(
    notificationType.DANGER,
    errorMessages.fourNotFour,
    notificationSetting
  );
};

const errorFourNotNine = () => {
  showNotification(
    notificationType.DANGER,
    errorMessages.fourNotNine,
    notificationSetting
  );
};

const errorFourOneZero = () => {
  showNotification(
    notificationType.DANGER,
    errorMessages.fourOneZero,
    notificationSetting
  );
};

const errorFourOneNine = () => {
  showNotification(
    notificationType.DANGER,
    errorMessages.fourOneNine,
    notificationSetting
  );
};

const errorFourTwoNine = () => {
  showNotification(
    notificationType.DANGER,
    errorMessages.fourTwoNine,
    notificationSetting
  );
};

const errorFiveHoundredSeries = () => {
  showNotification(
    notificationType.DANGER,
    errorMessages.fiveHoundredSeries,
    notificationSetting
  );
};

export function ErrorManagement(error) {
  if (error.response !== undefined) {
    switch (error.response.status) {
      case 400:
        errorFourHoundred(error.response);
        break;
      case 401:
        errorFourNotOne();
        break;
      case 402:
        errorFourNotTwo();
        break;
      case 403:
        errorFourNotThree();
        break;
      case 404:
        errorFourNotFour();
        break;
      case 409:
        errorFourNotNine();
        break;
      case 410:
        errorFourOneZero();
        break;
      case 419:
        errorFourOneNine();
        break;
      case 429:
        errorFourTwoNine();
        break;
      case 500:
        errorFiveHoundredSeries();
        break;
      case 502:
        errorFiveHoundredSeries();
        break;
      case 503:
        errorFiveHoundredSeries();
        break;
      case 504:
        errorFiveHoundredSeries();
        break;
      default:
        showNotification(
          notificationType.DANGER,
          error.response?.status,
          notificationSetting
        );
    }
  } else {
    showNotification(
      notificationType.DANGER,
      error.message,
      notificationSetting
    );
  }
}
